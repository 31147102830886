<template>
  <div>
    <div class="pure_top" style="position:relative;">
      <div style="position:absolute;z-index: 2;left:50%;margin-left: -126.5px;margin-top: 10px">
        <img src="../../public/static/img/logo.png">
      </div>
    </div>
    <div style="position: relative;margin-top: -50px">
      <div style="position:absolute;z-index: 1;left:50%;margin-left: -180px;
      background: whitesmoke;height: calc(100vh - 120px);width: 360px;border-radius:10px">

      </div>
      <div style="position:absolute;z-index: 1;left:50%;margin-left: -150px;">
        <div id="localStream" style="width: 300px;height: 180px;margin-top: 20px">
        </div>
        <form action="" method="post" class="basic-grey">
          <label>
            <span>考生姓名：</span>
            <span style="color: #0e0e0e"> {{ params.xm }}</span>
          </label>

          <label>
            <span>考试名称：</span>
            <span style="color: #0e0e0e">{{ params.ksmc }}</span>
          </label>
          <label>
            <span>科目名称：</span>
            <span style="color: #0e0e0e">{{ params.kmmc }}</span>
          </label>
          <label>
            <span>班级名称：</span>
            <span style="color: #0e0e0e">{{ params.bj }}</span>
          </label>
          <label>
            <span>机位代码：</span>
            <span style="color: #0e0e0e">{{ params.jwdm }}</span>
          </label>
          <label>
            <span>机位功能：</span>
            <span style="color: #0e0e0e">{{ params.jwgn|  filterJwgnmc }}</span>
          </label>

        </form>
      </div>
    </div>
    <!--    以下标签为辅助，各有作用-->
    <div id="hidepic" style="display:none;"></div>
    <div style="display:none;">
      <canvas id="myCanvas">
      </canvas>
    </div>
  </div>
</template>

<script>

import {handleAlert} from "@/utils/confirm";
import {client, createLocalStream, RoomControl, unPublishLocalStream} from "../utils/TRTCUtil";
import Vue from "vue";

export default {
  name: "monitoringCamera",
  destroyed() {
    clearInterval(this.countInterval)
    location.reload();
  },
  mounted() {
    clearInterval(this.countInterval)
    //url传过来的参数
    this.req = this.$route.query.req
    //获取学生基础信息
    this.getXsjcxx().then((res) => {
      this.params = res
      //校准时间信息
      this.getCountDown()
      //加入房间
      this.initializeMonitoring()
    })
  },
  data() {
    return {
      req: "",
      params: {},
      countInterval: null,      //倒计时句柄
      dqsj: 0,                  //当前时间戳
      test_count: 0,            //考试倒计时
      kssj: 0,                   //开始时间戳
      jssj: 0,                   //结束时间戳
    }
  },
  methods: {
    checkInterval() {
      this.dqsj += 1000;
      this.test_count += 1
      if (this.params.jwgn != 2) {
        if (this.test_count % Number(this.params.jwpzjg) == 0) {
          this.screenshotOfLocalVideoStream('localStream');
        }
      }
      //一分钟去校准一次时间，判断是否交卷
      if (this.test_count % 60 == 0) {
        this.getCountDown()
      }
      //如果已结束
      if (this.dqsj > this.jssj) {
        handleAlert("您的考试已结束", 'error')
        this.backToSelect()
      }
    },
    //校准时间
    getCountDown() {
      let parameter = {
        slid: this.params.slid,
        ksdm: this.params.ksdm,
        kmdm: this.params.kmdm,
        ksid: this.params.ksid,
      }
      this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/getCountDown', parameter).then((response) => {
        let resp = response.data
        if (resp.success) {
          let kssj = new Date(resp.content.kskssj);
          let jssj = new Date(resp.content.ksjssj);

          this.kssj = kssj.getTime()
          this.jssj = jssj.getTime()
          this.dqsj = resp.timestamp

          //如果已结束
          if (this.dqsj > this.jssj) {
            handleAlert("您的考试已结束", 'error')
            this.backToSelect()
          }

          //如果是初次加载，开启倒计时
          if (this.countInterval == null) {
            this.checkInterval();
            this.countInterval = setInterval(this.checkInterval, 1000);
          }

        } else {
          handleAlert(resp.message, 'error')
          this.backToSelect()
        }
      });
    },
    backToSelect() {
      this.$router.push("/examDetails")
    },
    getXsjcxx() {
      return new Promise((resolve) => {
        this.$ajax.post(localStorage.getItem('ApiUrl') + '/monitor/getXsjcxx', this.req).then((response) => {
          let resp = response.data;
          if (resp.success) {
            resolve(resp.content)
          } else {
            handleAlert(resp.message, 'error');
          }
        })
      });
    },
    async initializeMonitoring() {
      //房间id
      let roomId = this.params.slid + "_"
          + this.params.spfjid + "_"
          + this.params.ksdm + "_"
          + this.params.kmdm + "_"
          + this.params.roomId;
      // 用户id
      let userId = this.params.ksid + "_" + this.params.jwdm
      //机位功能是视频和定时拍照或者是仅视频模式下，加入房间
      if (this.params.jwgn == "0" || this.params.jwgn == "2") {
        await RoomControl.initializationClient(localStorage.getItem("ApiUrl"), userId,roomId)
        await client.join({'roomId': roomId}).then(() => {
          // 创建本地视频流
          createLocalStream(userId, (localStream) => {
            localStream.muteAudio();
            //推送视频流
            Vue.nextTick(() => {
              client.publish(localStream).then(() => {
              });
              //本地视频流播放
              localStream.play("localStream");
            });
          });
        }).catch(error => {
          console.error('进房失败 ' + error);
        });

      } else {
        await RoomControl.initializationClient(localStorage.getItem("ApiUrl"), userId,roomId)
        // 创建本地视频流
        await createLocalStream(userId, (localStream) => {
          localStream.muteAudio();
          //推送视频流
          Vue.nextTick(() => {
            //本地视频流播放
            localStream.play("localStream");
          });
        });
      }
    },
    screenshotOfLocalVideoStream(elementId) {
      //视频截成图片
      html2canvas(document.getElementById(elementId))
          .then((canvas) => {
            document.getElementById('hidepic').innerHTML = canvas;   //生成的原始canvas画布保留以备其他使用
            let img = new Image();
            let canvas1 = document.getElementById("myCanvas");
            // 设置宽高
            canvas1.width = canvas.width;//注意：没有单位
            canvas1.height = canvas.height;//注意：没有单位
            let ctx = canvas1.getContext("2d");
            let this_ = this
            if (img.complete) {
              img.src = canvas.toDataURL(); //由于图片异步加载，一定要等img加载好，再设置src属性
              img.onload = function () {
                // 绘制图片
                ctx.drawImage(img, 0, 0);
                // 绘制水印
                ctx.font = "12pt yahei";  //水印文字添加
                ctx.fillStyle = "#FFFFFF";

                ctx.fillText(this_.timestampToTime(this_.dqsj), 5, 170);

                let parm = {
                  kmdm: this_.params.kmdm,
                  slid: this_.params.slid,
                  ksdm: this_.params.ksdm,
                  ksid: this_.params.ksid,
                  bj: this_.params.bj,
                  xm: this_.params.xm,
                  jwdm: this_.params.jwdm
                }

                let base64Data = canvas1.toDataURL("image/jpeg", 0.8);

                let param = new FormData() // 创建form对象
                param.append('base64Data', base64Data)
                param.append('parm', JSON.stringify(parm));
                param.append('dqsj', this_.dqsj);
                param.append('type', "1");
                let config = {
                  headers: {'Content-Type': 'multipart/form-data'}
                } // 添加请求头
                this_.$ajax.post(localStorage.getItem('ApiUrl') + '/upload/uploadStudentPhotosRegularly', param, config).then((response) => {
                  let resp = response.data
                  if (resp.success) {
                    //当与服务器时间差距超过3秒时，校准为服务器时间
                    if (Math.abs(resp.timestamp - this_.dqsj) > 3000) {
                      this.dqsj = resp.timestamp;
                    }
                    if (!resp.content.success) {
                      console.error("实时图像上传异常错误原因" + JSON.parse(resp.content).message)
                    }
                  } else {
                    handleAlert(resp.message, 'error')
                  }
                });
              }
            }
          }).catch((err) => {
        console.error("实时图像上传异常错误原因" + err)
      });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return Y + M + D + h + m + s;

    }
  },
  filters: {
    filterJwgnmc(jwgn) {
      //获取机位功能名称
      switch (jwgn) {
        case "0":
          return "录像和拍照";
        case "1":
          return "仅定时拍照";
        case "2":
          return "仅录像";
      }
    }
  }
}
</script>

<style scoped="scoped">

.pure_top {
  width: 100%;
  height: 150px;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.pure_top::after {
  content: '';
  width: 120%;
  height: 150px;
  position: absolute;
  left: -10%;
  top: 0;
  border-radius: 0 0 50% 50%;
  background: linear-gradient(#289a89, #00bfb8);
}

/* Basic Grey */

.basic-grey {
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
  background: #F7F7F7;
  padding: 25px 15px 25px 0px;
  font: 18px Georgia, "Times New Roman", Times, serif;
  color: #888;
  text-shadow: 1px 1px 1px #FFF;
}

.basic-grey label {
  display: block;
  margin-bottom: 20px;
  border: 1px solid #DADADA;
  padding: 10px;
}


</style>

