import Vue from "vue"
import Router from "vue-router"
import Login from "./views/login.vue"
import ExamDetails from "./views/examDetails.vue"
import Answer from "./views/answer.vue"
import Finish from "./views/finish.vue"
import FinishQuestionnaire from "./views/finishQuestionnaire.vue"
import Timeup from "./views/timeup.vue"
// ========== 2022-12-21 洪璐 start ==============
import UploadImage from "./views/uploadImage.vue"
// ========== end ==============
import UploadSuccess from "./views/uploadSuccess.vue"
import Practice from "./views/practice";
import Questionnaire from "./views/questionnaire";
import SequentialPractice from "./views/sequentialPractice";
import MockExam from "./views/mockExam";
import MonitoringCamera from "./views/monitoringCamera";
import MonitoringSuccess from "./views/monitoringSuccess";

Vue.use(Router);

export default new Router({
    mode: "hash",
    base: process.env.BASE_URL,
    routes: [{
        path: "*",
        redirect: "/login",
    }, {
        path: "/login",
        component: Login
    }, {
        path: "/examDetails",
        component: ExamDetails,
        meta: {
            loginRequire: true
        }
    }, {
        path: "/answer",
        component: Answer,
        meta: {
            loginRequire: true
        }
    }, {
        path: "/practice",
        component: Practice,
        meta: {
            loginRequire: true
        }
    }, {
        path: "/questionnaire",
        component: Questionnaire,
        meta: {
            loginRequire: true
        }
    }, {
        name: 'sequentialPractice',
        path: "/sequentialPractice",
        component: SequentialPractice,
        meta: {
            loginRequire: true
        }
    }, {
        name: 'mockExam',
        path: "/mockExam",
        component: MockExam,
        meta: {
            loginRequire: true
        }
    }, {
        path: "/finish",
        component: Finish,
        meta: {
            loginRequire: true
        }
    }, {
        path: "/finishQuestionnaire",
        component: FinishQuestionnaire,
        meta: {
            loginRequire: true
        }
    }, {
        path: "/timeup",
        component: Timeup,
        meta: {
            loginRequire: true
        }
    },
    // ========== 2022-12-21 洪璐 start ==============
    {
        path: "/uploadImage",
        component: UploadImage,
        meta: {
            loginRequire: false
        }
    },
    // ========== end ==============
    {
        path: "/uploadSuccess",
        component: UploadSuccess,
        meta: {
            loginRequire: false
        }
    }, {
        path: "/monitoringCamera",
        component: MonitoringCamera,
        meta: {
            loginRequire: false
        }
    }, {
        path: "/monitoringSuccess",
        component: MonitoringSuccess,
        meta: {
            loginRequire: false
        }
    }]
})
