import TRTC from 'trtc-js-sdk';
import {sendAjax} from '../../public/static/TRTC/js/ajaxUtil'

export let userSig = null;
export const streamInfo = {};
export const userInfo = {name: "MyName"};
export let sdkAppId = null;
export let client = null;

/**
 * 创建本地视频流
 * @param userId
 * @param callback
 * @returns {LocalStream}
 */
export const createLocalStream = function (userId, callback) {
    // setup logging stuffs
    TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.WARN);
    TRTC.Logger.enableUploadLog();

    streamInfo.localStream = TRTC.createStream({userId: userId, audio: true, video: true});
    streamInfo.localStream.setVideoProfile('240p');
    streamInfo.localStream.initialize().then(() => {
        callback(streamInfo.localStream);
    });
    return streamInfo.localStream;
};

export function unPublishLocalStream(localStream) {
    return new Promise((resolve) => {
        client.unpublish(localStream).then(() => {
        });
        resolve(client)
    })
}


/***
 * 房间控制器
 */
export const RoomControl = {


    async initializationClient(apiUrl, userId_,roomId_) {
        // setup logging stuffs
        TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.WARN);
        TRTC.Logger.enableUploadLog();

        //初始化
        const UserSig = await genTestUserSig(apiUrl, userId_);
        sdkAppId = UserSig.content.sdkAppId;
        userSig = UserSig.content.userSig;

        client = TRTC.createClient({
            'mode': 'rtc',
            'sdkAppId': sdkAppId,
            'userId': userId_,
            'userSig': userSig,
            useStringRoomId: true,
            userDefineRecordId:roomId_+"_"+userId_
        });

        return client;
    },
    leave(client) {
        client.leave().then(() => {
            console.info('成功退出房间');
        }).catch(error => {
            console.error('leaving room failed: ' + error);
        });
    }

}


async function genTestUserSig(apiUrl, userId) {
    return await new Promise((resolve, reject) => {
        sendAjax({
            url: apiUrl + "/secretSig/getTencentCloudUserSig",
            type: 'post',
            data: {
                userId: userId,
            },
            dataType: 'json',
            timeout: 10000,
            contentType: "application/json",
            success: function (data) {
                data = JSON.parse(data)
                resolve(data);
            },
            //异常处理
            error: function (e) {
                console.log(e);
                reject(e);
            }
        })
    })
}