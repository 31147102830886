import Vue from 'vue'
import App from './app.vue'
import router from './router'
import axios from 'axios'


import '../public/static/theme/index.css'
import ElementUI from 'element-ui'

import * as auth from './utils/auth.js'
import qs from 'qs'
import {handleMessage} from "./utils/confirm";
import globalVariable from './utils/globalVariable'

import Exif from 'exif-js'
import VueCropper from 'vue-cropper'
import {Base64} from 'js-base64'
// import Vconsole from 'vconsole'
import 'formdata-polyfill'

Vue.config.productionTip = false;
Vue.prototype.$ajax = axios;
Vue.prototype.Exif = Exif
Vue.prototype.commonsVariable = globalVariable;

Vue.use(ElementUI)
Vue.use(VueCropper)
Vue.use(Base64)
// new Vconsole()

function initializeSystemParameters() {
    const version = "20240615";
    let url = 'static/json/'
    console.log('系统环境' + process.env.NODE_ENV)
    if (process.env.NODE_ENV === 'production') {
        url += 'prodConfig.json?t=' + Math.random() * 100000000000000000
    } else if (process.env.NODE_ENV === 'development') {
        url += 'devConfig.json?t=' + Math.random() * 100000000000000000
    } else {
        console.log('未检测到系统环境')
    }
    // 加载文件
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, false);
    xhr.overrideMimeType("text/html;charset=utf-8");//默认为utf-8
    xhr.send(null);
    let result = JSON.parse(xhr.responseText);
    localStorage.setItem('ApiUrl', result.apiUrl);
    localStorage.setItem('SystemVersion', result.version);
    localStorage.setItem('deviceTestingFunction', result.deviceTestingFunction);
    localStorage.setItem('ossDebugFunction', result.ossDebugFunction);
    console.log('main.js当前版本号：' + version)
    console.log('config.json当前版本号：' + result.version)
    console.log('设备测试功能：' + result.deviceTestingFunction)
    console.log('ossDebug功能：' + result.ossDebugFunction)
    if (result.version !== version) {
        window.location.reload(true);
    }
}

initializeSystemParameters();

//刷新Token
function refreshToken(refreshToken) {
    return axios.post(localStorage.getItem('ApiUrl') + '/secretSig/refreshToken'
        , qs.stringify({oldRefreshToken: refreshToken})).then(res => res.data)
}

let requests = [];
let isRefreshing = false;

/**
 * axios拦截器
 */
axios.interceptors.request.use(async config => {

    let accessToken = Tool.getAccessToken();
    if (Tool.isNotEmpty(accessToken)) {
        //带上token去后台请求
        config.headers['accessToken'] = accessToken;
    }
    let whitelist = []
    whitelist.push(localStorage.getItem('ApiUrl') + '/user/list')
    whitelist.push(localStorage.getItem('ApiUrl') + '/user/login')
    // whitelist.push(localStorage.getItem('ApiUrl') + '/answer/getCountDown')
    whitelist.push(localStorage.getItem('ApiUrl') + '/secretSig/refreshToken')
    whitelist.push(localStorage.getItem('ApiUrl') + '/secretSig/getTencentCloudUserSig')
    whitelist.push(localStorage.getItem('ApiUrl') + '/upload/getXsjcxx')
    whitelist.push(localStorage.getItem('ApiUrl') + '/upload/uploadStudentPhotosRegularly')
    whitelist.push(localStorage.getItem('ApiUrl') + '/monitor/getXsjcxx')
    whitelist.push(localStorage.getItem('ApiUrl') + '/monitor/turnOnMixedFlowByRoomIdAndKsid')
    // 白名单下的路径不需要拦截
    if (whitelist.includes(config.url)) {
        return config
    }
    //判断token是否过期，如果过期就刷新token
    if (auth.checkTokenExpired() && Tool.getRefreshToken()) {

        if (!isRefreshing) {
            isRefreshing = true;
            refreshToken(Tool.getRefreshToken()).then(res => {
                //真实的http响应code始终为200，这里取的是后台Result对象中的code
                const {code} = res;
                if (code == 200) {
                    const {accessToken, refreshToken} = res.content;
                    Tool.setAccessToken(accessToken);
                    Tool.setRefreshToken(refreshToken);
                    axios.defaults.headers['accessToken'] = accessToken;
                    isRefreshing = false;
                    return accessToken;
                } else {
                    isRefreshing = false;
                    return '';
                }
            }).then((accessToken) => {
                //console.log('刷新token成功，执行队列')
                requests.forEach(cb => cb(accessToken))
                // 执行完成后，清空队列
                requests = []
            }).catch(res => {
                console.error('刷新Token出错： ', res)
            })
        }

        const retryOriginalRequest = new Promise((resolve) => {
            requests.push((accessToken) => {
                // 因为config中的token是旧的，所以刷新token后要将新token传进来
                config.headers['accessToken'] = accessToken;
                resolve(config)
            })
        })
        return retryOriginalRequest
    }

    return config;
}, error => {
    Promise.reject(error)
});

// 定义响应拦截器
axios.interceptors.response.use(async response => {

        let data = response.data;
        //如果是拒绝请求，则提示并回到首页
        if (data.code === 403 && response.config.url.indexOf("/secretSig/refreshToken") == -1) {
            handleMessage(data.message, "error");
            Tool.removeAccessToken();
            Tool.removeRefreshToken();
            await router.push("/login")
        }
        return response;
    },
    error => {
        handleMessage("网络通信异常，请检查网络连接", "error");
        return Promise.reject(error)
    }
)

// 路由登录拦截
router.beforeEach((to, from, next) => {
    // 要不要对meta.loginRequire属性做监控拦截
    if (to.matched.some(function (item) {
        return item.meta.loginRequire
    })) {
        let accessToken = Tool.getAccessToken();
        if (Tool.isEmpty(accessToken)) {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');


