<template>
  <!--  问卷模式 -->
    <div>
        <!-- 全局加载界面-->
        <div v-loading.fullscreen.lock="fullscreenLoading"></div>
        <!-- 全局倒计时界面-->
        <div v-if="showCountDown" class="countdown-div">
            <span class="content">{{ ksxx.ksmc }}</span>
            <span class="content">{{ ksxx.kmmc }}</span>
            <span class="content">{{ kssj | getDateTime }}</span>
            <span class="content">{{ jssj | getDateTime }}</span>
            <span class="title">问卷尚未开始，请耐心等待</span>
            <span class="time">{{ wait_countdown | CountDownTime }}</span>
            <el-button type="primary" class="back-btn" plain @click="backToSelect">返回</el-button>
        </div>
        <!-- 最外部div -->
        <div class="outermost" v-if="showTestPaper && Object.keys(stxx).length>0">
            <!-- 头部 -->
            <header class="use-header">
                <!-- logo -->
                <div class="use-logo">
                    <img src="../../public/static/img/logo.png" alt="" class="logo_img">
                </div>
                <!-- 问卷名称 -->
                <div class="exam">
                    <h1 class="exam_text">
                        {{ ksxx.ksmc }}
                    </h1>
                    <!-- 手机目录 -->
                    <!--
                    <div class="list">
                      <img src="../../public/static/img/exam/list.png" alt="" class="list_img">
                    </div>
                    -->
                </div>
            </header>
            <!-- 页面主体 -->
            <main class="use-main">
                <!-- 左边部分 -->
                <article>
                    <!--学生区域 -->
                    <div class="message">
                        <!-- 学生照片 -->
                        <img src="../../public/static/img/exam/anyone.png" alt="" class="photo">
                        <!-- 学生信息 pc端-->
                        <hgroup>
                            <h2>姓名：&nbsp;{{ xsjbxx.xm }}</h2>
                            <h2>班级：&nbsp;{{ xsjbxx.bj }}</h2>
                            <h2>准考证号：&nbsp;{{ xsjbxx.ksid }}</h2>
                            <h2>问卷名称：&nbsp;{{ ksxx.kmmc }}</h2>
                        </hgroup>
                        <!-- 学生信息 手机端-->
                        <div class="hgroup_p1">
                            <span><i class="el-icon-user"></i> {{ xsjbxx.xm }}</span><br>
                            <span><i class="el-icon-notebook-1"></i> 进度: {{ answerProgress }}</span>
                        </div>
                        <div class="hgroup_p2">
                            <span><i class="el-icon-alarm-clock"></i> {{ test_countdown | CountDownTime }}</span>
                        </div>
                        <button class="btn_2" @click="finishKs">提 交</button>
                        <button class="btn" @click="finishKs">提 交</button>
                    </div>
                    <!--试题来源：题库-->
                    <div class="window" v-if="jksz.stly =='1'">
                        <!--循环大题-->
                        <div class="subject_main" v-for="dtk_tz in dtk" :key="dtk_tz.tzid">
                            <!-- 循环小题 -->
                            <div :class="jksz.sjms=='1'?('after window_2 d_jump_'+item.index):'window_2 d_jump_'+item.index"
                                 v-for="item in dtk_tz.stids" :key="item.index">
                                <!--显示材料题题干，闯关模式始终显示题干-->
                                <div
                                        v-if="item.zhstid!= null && item.zhstid!='' && (jksz.sjms=='1' || (jksz.sjms=='2' && dqst==item.index))"
                                        v-html="stxx[item.zhstid].tgxx"
                                        style="margin-bottom:20px"
                                ></div>
                                <!--具体小题信息-->
                                <div :id="item.no" v-if="(jksz.sjms=='1' || (jksz.sjms=='2' && dqst==item.index))">
<!--                                    {{ getStlxmc(stxx[item.id].stlx) }}（{{ sjxq[dtk_tz.tzid].tmfs }}分）-->
                                    <!--标记试题-->
                                    <label class="flag" style="float:right" v-if="jksz.sjms=='1'">
                                        <input type="checkbox" v-model="xszdxx[item.index].mark" class="flag_input">
                                        <span class="flag_span">标记</span>
                                        <img src="../../public/static/img/exam/pennant.png" alt="标记" class="flag_img">
                                    </label>
                                    <!--题干信息-->
                                    <div class="issue" v-html="item.no+'.'+stxx[item.id].tgxx">
                                    </div>
                                    <!--单选选项区域-->
                                    <div class="check" v-if="stxx[item.id].stlx=='1'">
                                        <!--这里有点绕，是为了实现选项打乱-->
                                        <label class="check_box" v-for="(xx,index) in xszdxx[item.index].xxsx"
                                               :key="item.index+'_'+index"
                                               v-bind:class="{active : checkIncludes(xszdxx[item.index].zdnr,xx)}">
                                            <input :name="'answer_'+item.index" type="radio" :value="xx"
                                                   v-model="xszdxx[item.index].zdnr"
                                                   :zsxx="xx" class="check_round" @change="changeDa(item.index)"/>
                                            <span class="check_span"
                                                  v-html="'&nbsp;'+indexToAlpha(index) + '. ' + xxxxToMap(stxx[item.id].xxxx)[xx]"></span>
                                        </label>
                                    </div>
                                    <!--多选项区域-->
                                    <div class="check" v-if="stxx[item.id].stlx=='2'">
                                        <!--这里有点绕，是为了实现选项打乱-->
                                        <!--多选增加了特殊处理，因为多选v-model双向绑定出来的是数组格式，所以没有使用v-model-->
                                        <label class="check_box" v-for="(xx,index) in xszdxx[item.index].xxsx"
                                               :key="item.index+'_'+index"
                                               v-bind:class="{active : checkIncludes(xszdxx[item.index].zdnr,xx)}">
                                            <input :name="'answer_'+item.index" type="checkbox" :value="xx"
                                                   @change="changeMuiltSelect(item.index);changeDa(item.index)"
                                                   v-bind:checked="checkIncludes(xszdxx[item.index].zdnr,xx)" :zsxx="xx"
                                                   class="check_round"/>
                                            <span class="check_span"
                                                  v-html="'&nbsp;'+indexToAlpha(index) + '. ' + xxxxToMap(stxx[item.id].xxxx)[xx]"></span>
                                        </label>
                                    </div>
                                    <!--判断题选项区域-->
                                    <div class="check" v-if="stxx[item.id].stlx=='3'">
                                        <!--判断题无需打乱顺序，写死两个选项-->
                                        <label class="check_box"
                                               v-bind:class="{active : checkIncludes(xszdxx[item.index].zdnr,'A')}">
                                            <input :name="'answer_'+item.index" type="radio" value="A"
                                                   v-model="xszdxx[item.index].zdnr"
                                                   class="check_round" @change="changeDa(item.index)"/>
                                            <span class="check_span"> 正确 </span>
                                        </label>
                                        <label class="check_box"
                                               v-bind:class="{active : checkIncludes(xszdxx[item.index].zdnr,'B')}">
                                            <input :name="'answer_'+item.index" type="radio" value="B"
                                                   v-model="xszdxx[item.index].zdnr"
                                                   class="check_round" @change="changeDa(item.index)"/>
                                            <span class="check_span"> 错误 </span>
                                        </label>
                                    </div>
                                    <!--填空题-->
                                    <div class="check" v-if="stxx[item.id].stlx=='4'">
                                        <!--在线答题-->
                                        <div v-if="jksz.dtfs=='1'">
                                            <el-input
                                                    class="check_input"
                                                    maxlength="100"
                                                    v-for="(xx,index) in JSON.parse(stxx[item.id].xxxx)"
                                                    :name="'answer_'+item.index"
                                                    :value="getTktZdnr(item.index,index)"
                                                    :key="item.index+'_'+index"
                                                    placeholder="请输入作答"
                                                    @input="changeInput(item.index);changeDa(item.index)"
                                                    @paste.native.capture.prevent="handleFalse"
                                                    @copy.native.capture.prevent="handleFalse"
                                                    @cut.native.capture.prevent="handleFalse"
                                                    show-word-limit
                                            >
                                                <template slot="prepend">{{ index + 1 }}</template>
                                            </el-input>
                                        </div>
                                        <!--拍照上传-->
                                        <div v-if="jksz.dtfs=='2'">
                                            <div v-for="txid in stxx[item.id].txqy.split(',')"
                                                 style="display:inline-block">
                                                <el-image :src="getImageUrl(txid,item.index)" class="zdimage"
                                                          :preview-src-list="getImageUrlArray(txid,item.index)">
                                                    <div slot="error" class="image-slot">
                                                        <img src="../../public/static/img/exam/failtoload.png"
                                                             style="width:80px;height:80px;"/>
                                                    </div>
                                                </el-image>
                                            </div>
                                            <div class="answer_div2">
                                                <!-- 二维码 -->
                                                <vue-qr
                                                        :text="getQrcodePath(stxx[item.id].ewmxx)"
                                                        :margin="0"
                                                        colorDark="#000"
                                                        colorLight="#fff"
                                                        :qid="'qrcode_'+stxx[item.id].stid"
                                                        :size="80">
                                                </vue-qr>
                                                <!-- 提示性文字 -->
                                                <p class="answer_text">
                                                    <span>扫码拍照上传主观题</span><br>
                                                    <a href="javascript:void(0)" @click="refreshImage(item.index)">点击刷新上传结果</a><br>
                                                    <a href="javascript:void(0)"
                                                       @click="qrcode_stid = item.id;showBigQrcode = true">看不清二维码？</a>
                                                </p>
                                            </div>
                                            <div class="answer_div3">
                                                <img src="../../public/static/img/exam/upload-img.png"
                                                     @click="qrcode_stid = item.id;refresh_stindex = item.index;showUploadDialog = true">
                                                <a href="javascript:void(0)"
                                                   @click="refreshImage(item.index)">点击刷新上传结果</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--简答题-->
                                    <div class="check" v-if="stxx[item.id].stlx=='5'">
                                        <!--在线答题-->
                                        <div v-if="jksz.dtfs=='1'">
                                            <el-input
                                                    type="textarea"
                                                    :rows="4"
                                                    class="check_input"
                                                    maxlength="1000"
                                                    :name="'answer_'+item.index"
                                                    v-model="xszdxx[item.index].zdnr"
                                                    @input="changeDa(item.index)"
                                                    @paste.native.capture.prevent="handleFalse"
                                                    @copy.native.capture.prevent="handleFalse"
                                                    @cut.native.capture.prevent="handleFalse"
                                                    placeholder="请输入作答"
                                                    show-word-limit
                                                    resize="none"
                                            >
                                            </el-input>
                                        </div>
                                        <!--拍照上传-->
                                        <div v-if="jksz.dtfs=='2'">
                                            <div v-for="txid in stxx[item.id].txqy.split(',')"
                                                 style="display:inline-block">
                                                <el-image :src="getImageUrl(txid,item.index)" class="zdimage"
                                                          :preview-src-list="getImageUrlArray(txid,item.index)">
                                                    <div slot="error" class="image-slot">
                                                        <img src="../../public/static/img/exam/failtoload.png"
                                                             style="width:80px;height:80px;"/>
                                                    </div>
                                                </el-image>
                                            </div>
                                            <div class="answer_div2">
                                                <!-- 二维码 -->
                                                <vue-qr
                                                        :text="getQrcodePath(stxx[item.id].ewmxx)"
                                                        :margin="0"
                                                        colorDark="#000"
                                                        colorLight="#fff"
                                                        :qid="'qrcode_'+stxx[item.id].stid"
                                                        :size="80">
                                                </vue-qr>
                                                <!-- 提示性文字 -->
                                                <p class="answer_text">
                                                    <span>扫码拍照上传主观题</span><br>
                                                    <a href="javascript:void(0)" @click="refreshImage(item.index)">点击刷新上传结果</a><br>
                                                    <a href="javascript:void(0)"
                                                       @click="qrcode_stid = item.id;showBigQrcode = true">看不清二维码？</a>
                                                </p>
                                            </div>
                                            <div class="answer_div3">
                                                <img src="../../public/static/img/exam/upload-img.png"
                                                     @click="qrcode_stid = item.id;refresh_stindex = item.index;showUploadDialog = true">
                                                <a href="javascript:void(0)"
                                                   @click="refreshImage(item.index)">点击刷新上传结果</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 闯关模式时，上一题 下一题 标记等辅助按钮 -->
                        <div class="window_3" v-show="jksz.sjms=='2'">
                            <button class="question1" @click="prex()">
                                <span>上一题</span>
                            </button>
                            <button class="question2" @click="next()">
                                <span>下一题</span>
                            </button>
                            <label class="flag">
                                <input type="checkbox" v-model="xszdxx[dqst].mark" class="flag_input">
                                <span class="flag_span">标记</span>
                                <img src="../../public/static/img/exam/pennant.png" alt="标记" class="flag_img">
                            </label>
                        </div>
                    </div>
                    <!--试题来源：图片上传-->
                    <div class="window" v-if="jksz.stly =='2'">
                        <img
                                class="source_img"
                                v-for="index of yjtxsl"
                                :key="index"
                                :src="ksxx.txdz + '/' + tokenInfo.slid + '/' + session.ksdm + '/' + session.kmdm + '/sample_source/sample_source_full_'+index+'.jpg'">
                    </div>
                    <!-- 作答区域  -->
                    <div class="answer" v-if="showAnswerArea">
                        <!-- 选项区 -->
                        <div class="answer_div1">
                            <!-- 题型 -->
                            <p class="question">
                                {{ stxx[dqstid].stmc }}.{{ getStlxmc(stxx[dqstid].stlx) }}（{{ stxx[dqstid].stmf }} 分）

                                <button class="question1" @click="prex()">
                                    <span>上一题</span>
                                </button>
                                <button class="question2" @click="next()">
                                    <span>下一题</span>
                                </button>
                                <label class="flag">
                                    <input type="checkbox" v-model="xszdxx[dqst].mark" class="flag_input">
                                    <span class="flag_span">标记</span>
                                    <img src="../../public/static/img/exam/pennant.png" alt="标记" class="flag_img">
                                </label>

                            </p>
                            <!--单选选项区域-->
                            <div class="pick" v-if="stxx[dqstid].stlx=='1'">
                                <label class="pick_label" v-for="(xx,index) in xszdxx[dqst].xxsx" :key="dqst+'_'+index">
                                    <input :name="'answer_'+dqst" type="radio" class="pick_input" :value="xx"
                                           v-model="xszdxx[dqst].zdnr"
                                           @change="changeDa(dqst)"/> {{ xx }}
                                </label>
                            </div>
                            <!--多选选项区域-->
                            <div class="pick" v-if="stxx[dqstid].stlx=='2'">
                                <label class="pick_label" v-for="(xx,index) in xszdxx[dqst].xxsx" :key="dqst+'_'+index">
                                    <input :name="'answer_'+dqst" type="checkbox" class="pick_input" :value="xx"
                                           @change="changeMuiltSelect(dqst);changeDa(dqst)"
                                           v-bind:checked="checkIncludes(xszdxx[dqst].zdnr,xx)"/> {{ xx }}
                                </label>
                            </div>
                            <!--简答题-->
                            <div class="check" v-if="stxx[dqstid].stlx=='5'">
                                <!--在线答题-->
                                <el-input
                                        v-if="jksz.dtfs=='1'"
                                        type="textarea"
                                        :rows="3"
                                        class="check_input"
                                        maxlength="1000"
                                        :name="'answer_'+dqst"
                                        v-model="xszdxx[dqst].zdnr"
                                        placeholder="请输入作答"
                                        show-word-limit
                                        resize="none"
                                        @change="changeDa(dqst)"
                                        @paste.native.capture.prevent="handleFalse"
                                        @copy.native.capture.prevent="handleFalse"
                                        @cut.native.capture.prevent="handleFalse"
                                >
                                </el-input>
                                <!--拍照上传-->
                                <div v-if="jksz.dtfs=='2'">
                                    <div v-for="txid in stxx[dqstid].txqy.split(',')" style="display:inline-block">
                                        <el-image :src="getImageUrl(txid,dqst)" class="zdimage"
                                                  :preview-src-list="getImageUrlArray(txid,dqst)">
                                            <div slot="error" class="image-slot">
                                                <img src="../../public/static/img/exam/failtoload.png"
                                                     style="width:80px;height:80px;"/>
                                            </div>
                                        </el-image>
                                    </div>
                                    <div class="answer_div2">
                                        <!-- 二维码 -->
                                        <vue-qr
                                                :text="getQrcodePath(stxx[dqstid].ewmxx)"
                                                :margin="0"
                                                colorDark="#000"
                                                colorLight="#fff"
                                                :qid="'qrcode_'+stxx[dqstid].stid"
                                                :size="80">
                                        </vue-qr>
                                        <!-- 提示性文字 -->
                                        <p class="answer_text">
                                            <span>扫码拍照上传主观题</span><br>
                                            <a href="javascript:void(0)"
                                               @click="refreshImage(dqst)">点击刷新上传结果</a><br>
                                            <a href="javascript:void(0)"
                                               @click="qrcode_stid = dqstid;showBigQrcode = true">看不清二维码？</a>
                                        </p>
                                    </div>
                                    <div class="answer_div3">
                                        <img src="../../public/static/img/exam/upload-img.png"
                                             @click="qrcode_stid = dqstid;refresh_stindex = dqst;showUploadDialog = true">
                                        <a href="javascript:void(0)" @click="refreshImage(dqst)">点击刷新上传结果</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <!-- 右边部分 =============================== -->
                <aside>
                    <!-- 表头答题卡 -->
                    <div class="card_head">
                        <div class="ch_left">
                            <span><i class="el-icon-notebook-2"></i> 试题列表</span>
                        </div>
                        <div class="ch_right">
                            <span><i class="el-icon-alarm-clock"></i> {{ test_countdown | CountDownTime }}</span>
                        </div>
                    </div>
                    <!-- 答题卡主体 -->
                    <div class="subject">
                        <div class="subject_main" style="display: flex">
                            <div class="subject_bottom">
                                <template  v-for="dtk_tz in dtk">
                                    <div class="box"
                                         v-bind:class="{ mark : xszdxx[item.index].mark , finish : xszdxx[item.index].zdnr!='' , active : (dqst==item.index && jksz.sjms=='2') }"
                                         v-for="item in dtk_tz.stids"
                                         @click="changeSt(item.index)">
                                        {{ jksz.stly == '1' ? item.no : stxx[item.id].stmc }}
                                        <i></i>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- 答题卡底部说明 -->
                    <div class="sign">
                        <div class="sign_div">
                            <div class="cube1"></div>
                            <span>未做</span>
                        </div>
                        <!--只有闯关模式才有当前试题的概念-->
                        <div class="sign_div" v-if="jksz.sjms=='2'">
                            <div class="cube2"></div>
                            <span>当前</span>
                        </div>
                        <div class="sign_div">
                            <div class="cube3"></div>
                            <span>已做</span>
                        </div>
                        <div class="sign_div">
                            <div class="cube4"><i></i></div>
                            <span>标记</span>
                        </div>
                    </div>
                </aside>
            </main>
        </div>
        <!-- 放大二维码 -->
        <el-dialog :title="stxx[qrcode_stid].stmc" :visible.sync="showBigQrcode" v-if="showBigQrcode" width="450px"
                   :center=true>
            <vue-qr
                    :text="getQrcodePath(stxx[qrcode_stid].ewmxx)"
                    :margin="0"
                    colorDark="#000"
                    colorLight="#fff"
                    :qid="'qrcode_'+stxx[qrcode_stid].stid"
                    :size="400">
            </vue-qr>
        </el-dialog>
        <!-- 拍照上传界面 -->
        <el-dialog
                title="拍照上传主观题"
                :visible.sync="showUploadDialog"
                v-if="showUploadDialog"
                @close="refreshImage(refresh_stindex)"
                fullscreen
                center>
            <div style="height:calc( 100vh - 120px )">
                <iframe
                        :src="getQrcodePath(stxx[qrcode_stid].ewmxx)"
                        frameborder="0"
                        width="100%"
                        height="100%"
                ></iframe>
            </div>
        </el-dialog>
        <!--    <Mqtt ref="mqtt" @sendModel="sendModel"></Mqtt>-->
    </div>
</template>

<script>
import {handleAlert} from "@/utils/confirm";
import {getTokenInfo} from "@/utils/auth";
import vueQr from 'vue-qr'
//MQTT 组件
// import Mqtt from '@/components/Mqttws';
export default {
    name: "questionnaire",
    components: {
        vueQr
    },
    data: function () {
        return {
            tokenInfo: null,           //token信息
            session: null,               //会话信息
            showCountDown: false,      //是否显示倒计时
            showConfirm: false,        //是否显示确认提交
            showTestPaper: false,      //是否显示答题区
            fullscreenLoading: true,   //全局loading
            showAnswerArea: false,     //是否显示作答区域
            qrcode_stid: null,         //放大的二维码ID
            refresh_stindex: null,     //刷新的试题序号
            showBigQrcode: false,      //是否显示大二维码
            showUploadDialog: false,   //显示上传图片的对话框
            dtk: [],                   //右侧答题卡
            sjxq: [],                  //试卷详情
            jksz: {},                  //机考设置
            ksxx: {},                  //问卷科目
            xsjbxx: {},                //学生信息
            qtList: [],                 //取题信息
            xszdxx: [],                //作答信息
            dqst: 0,                   //当前试题
            dqstid: 0,                 //当前试题ID
            kssj: 0,                   //开始时间戳
            jssj: 0,                   //结束时间戳
            dqsj: 0,                   //当前时间戳
            countInterval: null,       //倒计时句柄
            wait_countdown: 0,         //等待界面倒计时
            test_countdown: 0,         //问卷倒计时
            wait_store_countdown: 0,   //等待持久化的倒计时
            stxx: {},                  //试题信息
            yjtxsl: 0,                 //原卷图像数量（用于图片上传方式显示试卷使用）
            waitStoreMap: null,          //等待持久化的数据，key是stid，value是时间戳及作答内容
            answerProgress: '',          //作答进度
        }
    },
    watch: {
        dqst() {
            this.dqstid = this.xszdxx[this.dqst].stid
        }
    },
    filters: {
        getDateTime(value) {
            let date = new Date(value);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            let m = date.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = date.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
        },
        SubSeconds(item) {
            return item.substring(0, 16);
        },
        CountDownTime(item) {
            let i_item = parseInt(item);
            let hour = parseInt(i_item / 3600)
            i_item = item % 3600
            let minute = parseInt(i_item / 60)
            let second = parseInt(item % 60)
            if (hour > 0) {
                return `${hour} : ${minute} : ${second}`
            } else {
                return `${minute} : ${second}`
            }
        }
    },
    mounted: function () {
        this.session = SessionStorage.get(SESSION_KEY_EXAM_ANSWER)
        this.tokenInfo = getTokenInfo()
        //判断session中是否有问卷信息
        if (Tool.isEmpty(this.session)) {
            this.backToSelect();
            return;
        }
        //去后台获取学生信息
        this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/initExam', this.session).then((response) => {
            let resp = response.data
            if (resp.success) {
                let {jksz, ksxx, xsjbxx, xszdxx} = resp.content
                //初始化一些变量
                this.sjxq = JSON.parse(jksz.sjxq);
                this.jksz = jksz
                this.ksxx = ksxx
                this.xsjbxx = xsjbxx
                this.xszdxx = xszdxx
                this.dqstid = xszdxx[this.dqst].stid
                this.waitStoreMap = new Map()
                this.wait_store_countdown = parseInt(Math.random() * this.jksz.bcjg); //随机化储存周期，让每个人错峰写入数据库
                //将待获取试题放入数组中
                this.sjxq.forEach(tzItem => {
                    if (!(tzItem.stids instanceof Array)) {
                        tzItem.stids = JSON.parse(tzItem.stids)
                    }
                    tzItem.stids.forEach(stItem => {
                        this.qtList.push(stItem.stid)
                        if (stItem.childIds !== null && stItem.childIds !== undefined) {
                            //如果存在组合题
                            stItem.childIds.forEach(childItem => {
                                this.qtList.push(childItem)
                            })
                        }
                    })
                })
                if (Tool.isEmpty(this.xsjbxx.kskssj)) {
                    //尚未开始过问卷，需要弹框确认问卷
                    this.startTest()
                } else {
                    //已经开考，直接进入初始化逻辑
                    this.initWorkSpace()
                }

            } else {
                handleAlert(resp.message, 'error')
                this.backToSelect()
            }
        })
    },
    beforeDestroy() {
        if (this.countInterval) {
            clearInterval(this.countInterval);
        }
    },
    methods: {
        // MQTT 接受到的订阅信息
        // sendModel(msg) {
        //   var data = JSON.parse(msg);
        //   let ksid = this.xsjbxx.ksid; // 学生的问卷ID 用作对比使用
        //   if (ksid === data.ksid) {
        //     this.getSTIndexById(data.stid);
        //   }
        //   //console.log('订阅信息---', data)
        // },
        //返回选择菜单
        backToSelect: function () {
            this.$router.push("/examDetails")
        },
        //获取图像地址
        getImageUrl: function (txid, index) {
            return this.ksxx.txdz + '/' + this.tokenInfo.slid + '/' + this.session.ksdm + '/' + this.session.kmdm + '/' + this.tokenInfo.ksid + '/' + this.tokenInfo.ksid + '_' + txid + '.jpg?ver=' + this.xszdxx[index].zdnr
        },

        //获取当前地址
        getQrcodePath: function (ewmxx) {
            return window.location.protocol + "//" + window.location.host + "/#/uploadImage?req=" + ewmxx;
        },
        //关闭上传图片对话框的回调（刷新上传）
        refreshImage: function (index) {
            let temp = {};
            temp.ksdm = this.session.ksdm;
            temp.kmdm = this.session.kmdm;
            temp.stid = this.xszdxx[index].stid;
            //获取学生拍照上传的作答信息
            // 增加一个正在Loding 的动画
            this.fullscreenLoading = true;
            this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/getXszdxx', temp).then((response) => {
                let resp = response.data
                this.fullscreenLoading = false;
                if (resp.success) {
                    let xszd = resp.content
                    this.xszdxx[index].zdnr = xszd.zdnr;
                } else {
                    handleAlert(resp.message, 'error')
                    this.backToSelect()
                }
            })
        },
        // 获取一个题目的index
        getSTIndexById(stid) {
            try {
                this.xszdxx.forEach((element, index) => {
                    if (element.stid == Number(stid)) {
                        this.refreshImage(index);
                        throw new Error("");
                    }
                });
            } catch (error) {
                //console.log("循环结束");
            }
        },
        //获取图像地址（数组）
        getImageUrlArray: function (txid, index) {
            let arr = [];
            arr.push(this.getImageUrl(txid, index));
            return arr;
        },

        //开始问卷
        startTest: function () {
            //开始问卷
            this.$ajax.post(localStorage.getItem('ApiUrl') + '/questionnaire/startQuestionnaire', this.session).then((response) => {
                let resp = response.data
                if (resp.success) {
                    this.initWorkSpace()
                } else {
                    handleAlert(resp.message, 'error')
                    this.backToSelect()
                }
            });
        },
        //初始化问卷界面
        initWorkSpace: function () {
            //清除以往的倒计时
            clearInterval(this.countInterval)
            //获取试题
            this.getTestPaper()
            //开始计算倒计时
            this.getCountDown()
        },

        //校准时间
        getCountDown: function () {
            this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/getCountDown', this.session).then((response) => {
                let resp = response.data
                if (resp.success) {
                    let kssj = new Date(resp.content.kskssj);
                    let jssj = new Date(resp.content.ksjssj);
                    this.kssj = kssj.getTime()
                    this.jssj = jssj.getTime()
                    console.log(jssj.getTime())
                    this.dqsj = resp.timestamp
                    //如果已结束
                    if (this.dqsj > this.jssj) {
                        handleAlert("您的问卷已结束", 'error')
                        this.backToSelect()
                    }
                    //如果是初次加载，开启倒计时
                    if (this.countInterval == null) {
                        this.checkInterval();
                        this.countInterval = setInterval(this.checkInterval, 1000);
                    }
                } else {
                    handleAlert(resp.message, 'error')
                    this.backToSelect()
                }
            });
        },

        //开始倒计时循环
        checkInterval: function () {
            this.dqsj += 1000;
            //尚未开始
            if (this.dqsj + 1000 < this.kssj) {
                this.showCountDown = true
                this.showTestPaper = false
                this.wait_countdown = parseInt((this.kssj - this.dqsj) / 1000)
                return
            }
            //已经开始
            if (this.dqsj < this.jssj) {
                //执行答案持久化
                this.saveDa()
                this.showCountDown = false
                this.showTestPaper = true
                this.test_countdown = parseInt((this.dqsj - this.kssj) / 1000)
            }
        },
        //保存答案
        saveDa: function () {
            let answerTotal = this.xszdxx.length;
            let yzdAnswerTotal = 0;
            this.xszdxx.forEach(item => {
                if (item.zdnr != '') {
                    yzdAnswerTotal++
                }
            })
            //计算答题进度
            this.answerProgress = yzdAnswerTotal + ' / ' + answerTotal
            //如果不实时保存，则退出
            if (this.jksz.bcjg == -1) {
                return
            }
            this.wait_store_countdown++;
            //超过保存间隔，执行自动保存
            if (this.wait_store_countdown > this.jksz.bcjg) {
                this.wait_store_countdown = 0;
                //无任何修改，等待下一周期
                if (this.waitStoreMap.size == 0) {
                    return
                }
                //记录当前时间戳，用于判断是否删除掉待持久任务
                let sjc = new Date().getTime()
                //要把map对象转换为普通的json对象后台才能接收
                let data = {}, answer = {}
                for (let [k, v] of this.waitStoreMap) {
                    answer[k] = v
                }
                data.ksid = this.tokenInfo.ksid
                data.ksdm = this.session.ksdm
                data.kmdm = this.session.kmdm
                data.answers = answer
                //执行持久化逻辑
                this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/saveAnswerMap', data).then((response) => {
                    let resp = response.data
                    if (resp.success) {
                        //当与服务器时间差距超过3秒时，校准为服务器时间
                        if (Math.abs(resp.timestamp - this.dqsj) > 3000) {
                            this.dqsj = resp.timestamp;
                        }
                        //根据时间戳删掉已经被持久化的数据
                        for (let [k, v] of this.waitStoreMap) {
                            if (v.sjc < sjc) {
                                this.waitStoreMap.delete(k);
                            }
                        }
                    } else {
                        handleAlert(resp.message, 'error')
                        this.backToSelect()
                    }
                });
            }
        },
        //获取试题信息
        getTestPaper: function () {
            let sjms = this.jksz.sjms  //试卷模式  1试卷 2闯关
            let stly = this.jksz.stly  //试题来源  1题库组卷 2图片上传
            let dtfs = this.jksz.dtfs  //答题方式  1在线答题 2拍照上传
            /*
             * 不管什么模式，一律从stxx表中取题，不再涉及zgtxxb和kgtxxb
             * 题库组卷 + 在线答题  正常（试卷、闯关均可），
             * 题库组卷 + 拍照上传  正常（试卷、闯关均可）
             * 图片上传 + 在线答题  图片上传一定是闯关模式，并且答题区在最下方
             * 图片上传 + 拍照上传  图片上传一定是闯关模式，并且答题区在最下方
             */
            //初始化答题卡
            this.changeZdbToTree();
            //获取试题信息的参数
            let getStxxParam = this.session;
            getStxxParam.bmh = this.xsjbxx.bmh;
            if (stly == '1') {
                this.session.qtList = this.qtList
                //题库组卷
                this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/getQbankStxx', getStxxParam).then((response) => {
                    this.fullscreenLoading = false;
                    this.showAnswerArea = false;
                    let resp = response.data
                    if (resp.success) {
                        //list转map方便使用
                        let map = {}
                        resp.content.forEach(row => {
                            map[row.stid] = row
                        })
                        this.stxx = map;
                        //添加组合题判断逻辑
                        this.addZhtgxxLogic();

                    } else {
                        handleAlert(resp.message, 'error')
                        this.backToSelect()
                    }
                });
            } else {
                //传统图片上传方式
                this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/getImageStxx', getStxxParam).then((response) => {
                    let resp = response.data
                    if (resp.success) {
                        this.yjtxsl = resp.content.gsdyxx.yjtxsl
                        //list转map方便使用
                        let map = {}
                        resp.content.stxxList.forEach(row => {
                            map[row.stid] = row
                        })
                        this.stxx = map;
                        this.fullscreenLoading = false
                        this.showAnswerArea = true
                    } else {
                        handleAlert(resp.message, 'error')
                        this.backToSelect()
                    }
                });
            }
        },
        //鼠标点击试题
        changeSt: function (index) {
            // 洪璐 2021年2月25日 查询到对应DIV 调用 scrollIntoView 方法滚动。
            if (this.jksz.sjms == '1') {
                (document.querySelectorAll('.d_jump_' + index))[0].scrollIntoView({block: "start", behavior: "smooth"});
            }
            this.dqst = index
        },
        //上一题
        prex: function () {
            if (this.dqst > 0) {
                this.dqst--
            }
        },
        //下一题
        next: function () {
            if (this.dqst < this.xszdxx.length - 1) {
                this.dqst++
            }
        },
        //数字下标转换为字母
        indexToAlpha: function (index) {
            let alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            if (index > 25 || index < 0) {
                return ''
            }
            return alpha[index]
        },
        //选项信息转换为map
        xxxxToMap: function (str) {
            let xxxx = JSON.parse(str)
            let map = {}
            xxxx.forEach(xx => {
                map[xx.xx] = xx.nr
            })
            return map
        },
        //获取试题类型名称
        getStlxmc: function (stlxid, default_name = '') {
            switch (stlxid) {
                case '1':
                    return '单选题';
                case '2':
                    return '多选题';
                case '3':
                    return '判断题';
                case '4':
                    return '填空题';
            }
            return default_name;
        },

        //检查是否包含
        checkIncludes: function (str, obj) {
            if (str == null || str == '') {
                return false
            }
            if (str.includes(obj)) {
                return true
            }
            return false
        },
        //多选选中逻辑
        changeMuiltSelect: function (index) {
            let arr = document.getElementsByName("answer_" + index);
            let zd = '';
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].checked) {
                    arr.class = "check_box active"
                    zd += arr[i].value
                }
            }
            this.xszdxx[index].zdnr = zd
        },
        changeInput: function (index) {
            let arr = document.getElementsByName("answer_" + index)
            let zd = []
            let flag = false

            for (let i = 0; i < arr.length; i++) {
                zd.push(arr[i].value)
                if (arr[i].value != null && arr[i].value != '') {
                    flag = true
                }
            }
            if (flag) {
                this.xszdxx[index].zdnr = JSON.stringify(zd)
            } else {
                this.xszdxx[index].zdnr = ''
            }
        },
        //修改答案
        changeDa: function (index) {
            let obj = {}
            //当前时间戳（本地时间，不作为写入数据库依据，仅仅为判断内容新旧）
            obj.sjc = new Date().getTime()
            //写入数据库的为真正的作答时间
            obj.zdsj = this.dqsj
            obj.zdnr = this.xszdxx[index].zdnr
            //存入待保存序列
            this.waitStoreMap.set(this.xszdxx[index].stid, obj)
        },
        //获取填空题内容
        getTktZdnr: function (stxh, index) {
            let zdnr = this.xszdxx[stxh].zdnr;
            if (zdnr == null || zdnr == '') {
                return ''
            }
            if (typeof zdnr == 'string') {
                try {
                    return JSON.parse(zdnr)[index]
                } catch (e) {
                    return '';
                }
            }
            return zdnr[index]
        },
        //提交
        finishKs: function () {
            this.$confirm('提交后将无法再次修改问卷答案，您确定要提交吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                //要把map对象转换为普通的json对象后台才能接收
                let data = {}, answer = {}
                for (let [k, v] of this.waitStoreMap) {
                    answer[k] = v
                }
                data.ksid = this.tokenInfo.ksid
                data.ksdm = this.session.ksdm
                data.kmdm = this.session.kmdm
                data.answers = answer
                //执行提交逻辑
                this.$ajax.post(localStorage.getItem('ApiUrl') + '/questionnaire/finishQuestionnaire', data).then((response) => {
                    let resp = response.data
                    if (resp.success) {
                        //提交成功
                        SessionStorage.set(SESSION_KEY_FINISH, "success");
                        this.$router.push("/finishQuestionnaire")
                    } else {
                        handleAlert(resp.message, 'error')
                    }
                });
            }).catch((err) => {
            });
        },
        //将作答表转换为答题卡列表数据
        changeZdbToTree: function () {
            let dtk = []
            let index = 1
            let last_tzid = null
            let tzxx = {}
            for (let i = 0; i < this.xszdxx.length; i++) {
                let zdxx = this.xszdxx[i];
                if (zdxx.tzid != last_tzid) {
                    if (last_tzid != null) {
                        dtk.push(tzxx)
                    }
                    last_tzid = zdxx.tzid
                    tzxx = {}
                    tzxx["tzid"] = zdxx.tzid
                    tzxx["tzmc"] = this.sjxq[zdxx.tzid].tzmc
                    tzxx["tmsl"] = this.sjxq[zdxx.tzid].tmsl
                    tzxx["stids"] = []
                }
                let st = {}
                st["id"] = zdxx.stid
                st["index"] = index - 1
                st["no"] = index++
                tzxx.stids.push(st)
            }
            //将最后遗漏的补进去
            dtk.push(tzxx)
            this.dtk = dtk;
        },
        //添加组合题题干信息逻辑
        addZhtgxxLogic: function () {
            let zhtgidSet = new Set()
            for (let i = 0; i < this.dtk.length; i++) {
                let stids = this.dtk[i].stids
                for (let j = 0; j < stids.length; j++) {
                    if (!Tool.isEmpty(this.stxx[this.dtk[i].stids[j].id].zhstid)) {
                        let zhstid = this.stxx[this.dtk[i].stids[j].id].zhstid
                        if (this.jksz.sjms == '2' || !zhtgidSet.has(zhstid)) {
                            this.dtk[i].stids[j].zhstid = zhstid
                            zhtgidSet.add(zhstid)
                        }
                    }
                }
            }
        },
        handleFalse() {
            //终止
            return false;
        }
    }

}
</script>
<style>
img {
    max-width: 600px;
}
</style>
