// 引入 token，此处直接引入，也可以在 main.js 中全局引入
import {Base64} from "js-base64";

// 检查token是否过期
export function checkTokenExpired() {

  let tokenInfo = getTokenInfo();
  let now = new Date().getTime();
  if(JSON.stringify(tokenInfo) == "{}"){
    return true;
  }

  //剩余时间在30分钟以内就提前换token
  if (tokenInfo.exp - now < (30*60*1000)) {
    return true;
  }
  return false;
}

// 获取Token中的信息
export function getTokenInfo() {
  try{
    let token = Tool.getAccessToken();
    if(token == null || token == ''){
      return {};
    }
    let pyLoad = token.split('.')[1]
    pyLoad = JSON.parse(Base64.decode(pyLoad));
    pyLoad["exp"] = new Date(pyLoad.exp * 1000).getTime()

    return pyLoad;
  }catch(e){
    return {};
  }
}
