<template>
    <div style="text-align:center;position:fixed;top:50%;left: 0;right: 0;transform:translateY(-50%);">
        <h1><img src="../../public/static/img/uploadImg/successbg.png" alt="" style="max-width:100%;height: auto;vertical-align:middle;border: 0"></h1>
        <h3 style="color: #4a4a4a;font-size: 24px;line-height:24px;font-weight:600">作答上传成功！</h3>
    </div>
</template>

<script>
    export default {
        name: "uploadSuccess"
    }
</script>

<style scoped>

</style>