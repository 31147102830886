<template>
  <div class="cg-main">
    <div class="cg-big">
      <div class="cg-big_d">
        <img src="../../public/static/img/finish/jiaojuan.png" alt="" class="cg_img">
        <p class="cg-p">交卷成功！</p>
      </div>
      <button class="cg-btn" @click="backToSelect">返回首页</button>
    </div>

  </div>
</template>

<script>
export default {
  name: "finish",
  data: function (){
    return {
      slxx: [],
      session: null,
    }
  },

  mounted: function() {
    let _this = this;
    _this.session = SessionStorage.get(SESSION_KEY_FINISH);

    if(Tool.isEmpty(_this.session)){
      _this.backToSelect();
      return;
    }else{
      SessionStorage.remove(SESSION_KEY_FINISH);
    }
  },
  methods: {
    backToSelect: function(){
      this.$router.push("/examDetails")
    }
  }
}
</script>