<template>
  <!-- 让这个div代替body -->
  <div class="login-max">
    <!-- 上方logo -->
    <header class="login-header">
      <img src="static/img/logo.png" alt="" class="logo">
    </header>
    <!-- 中间白色内容区域 -->
    <main class="login-main">
      <div class="big">
        <!-- 左边插图部分 -->
        <div class="left">
          <img src="static/img/login/ct.png" alt="">
        </div>
        <!-- 右边登录框 -->
        <div class="right">
          <div class="right_inside">
            <p class="right_text">
              <span class="text1">Hello~</span> <br>
              <span class="text2">欢迎来到在线考试</span>
            </p>
            <div v-if="slxx.length > 1">
              <select v-model="xsjcxx.slid" class="box1">
                <option value="">请选择实例</option>
                <option v-for="o in slxx" :value="o.slid">{{ o.slmc }}</option>
              </select>
            </div>
            <input v-model="xsjcxx.ksid" type="text" placeholder="用户名" class="box2">
            <input v-model="xsjcxx.dlmm" type="password" placeholder="密码" class="box3" @keyup.enter="login">
            <button v-on:click="login" class="btn3">登 录</button>
            <p class="login-base">
              <a href="javascript:;" v-on:click="forgetPsssword" class="login-mm">忘记密码</a>
            </p>
          </div>
        </div>
      </div>
    </main>

    <!--底部商业信息  -->
    <div class="login-footer">
      <span>智多分教育科技 © CloudExam - {{ date }}</span>
    </div>

    <el-dialog
        title="修改密码"
        :visible.sync="showChangePwd"
        :show-close="true"
        width="85%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <el-form
          :model="dataForm"
          label-width="80px"
          :rules="dataFormRules"
          ref="dataForm"
          :size="size"
          label-position="right"
      >
        <el-form-item label="原密码" prop="lastpassword">
          <el-input
              v-model="dataForm.lastpassword"
              placeholder="请输入原密码"
              type="password"
              auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newpassword">
          <el-input
              v-model="dataForm.newpassword"
              placeholder="请设置新密码"
              type="password"
              auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="qrpassword">
          <el-input
              v-model="dataForm.qrpassword"
              placeholder="请确认新密码"
              type="password"
              auto-complete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :size="size" v-on:click="showChangePwd = false"
        >取消
        </el-button
        >
        <el-button
            :size="size"
            type="primary"
            v-on:click="submitForm"
            :loading="editLoading"
        >提交
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {handleAlert, handleConfirm, handleMessage, Validator} from "@/utils/confirm";

export default {
  name: "login",
  data: function () {
    //此处即表单发送验证之前，验证新密码与原密码
    let validateNewPassword = (rule, value, callback) => {
      if (value === this.dataForm.lastpassword) {
        callback(new Error("新密码不能与原密码相同!"));
      } else if (value.toString().length < 6 || value.toString().length > 18) {
        callback(new Error("密码长度为6-18位"));
      } else {
        callback();
      }
    };

    //此处即表单发送之前验证  验证新密码与再次确认
    let validateNewPassword2 = (rule, value, callback) => {
      if (value !== this.dataForm.newpassword) {
        callback(new Error("与新密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      slxx: [],
      xsjcxx: {ksid: '', dlmm: '', slid: ''},
      date: Tool.transTimestampToYear(),
      showChangePwd: false, //是否显示修改密码弹框
      dialogWidth: "", //dialog的宽度，缩放浏览器改变大小editLoading: false, //载入图标
      size: "small",
      editLoading: false, //载入图标
      dataForm: {
        lastpassword: "",
        newpassword: "",
        qrpassword: "",
      },
      //设置属性
      dataFormRules: {
        lastpassword: [
          {required: true, message: "请输入原密码", trigger: "blur"},
        ],
        newpassword: [
          {required: true, message: "请设置新密码", trigger: "blur"},
          {validator: validateNewPassword, trigger: "blur"},
        ],
        qrpassword: [
          {required: true, message: "请确认新密码", trigger: "blur"},
          {validator: validateNewPassword2, trigger: "blur"},
        ],
      }
    }
  },

  mounted: function () {
    this.list();
  },
  methods: {
    list() {
      let body = {
        version: localStorage.getItem("SystemVersion"),
      };
      this.$ajax.post(localStorage.getItem('ApiUrl') + '/user/list?t='+Math.random() * 100000000000000000,body).then((response) => {
        let resp = response.data;
        if (resp.success) {
          this.slxx = resp.content;
          if (this.slxx.length === 1) {
            this.xsjcxx.slid = this.slxx[0].slid;
          }
        } else {
          handleAlert(resp.message, "error");
        }
      }).catch(res => {
        handleAlert("请求异常", res.message);
      })
    },
    login() {
      //登录校验
      if (!Validator(this.xsjcxx.ksid, '用户名')
          || !Validator(this.xsjcxx.dlmm, '密码')
          || !Validator(this.xsjcxx.slid, "实例")) {
        return;
      }
      //去掉空格
      this.xsjcxx.ksid = this.xsjcxx.ksid.trim()
      this.xsjcxx.dlmm = this.xsjcxx.dlmm.trim()
      this.$ajax.post(localStorage.getItem('ApiUrl') + '/user/login', this.xsjcxx).then((response) => {
        let resp = response.data;
        if (resp.success) {
          Tool.setAccessToken(resp.content.accessToken);
          Tool.setRefreshToken(resp.content.refreshToken)
          if (resp.content.jyxgmmbz) {
            if(resp.content.sfyxtgxgmmbz){
              this.$alert('您的密码为初始密码，为了您的账号安全，请立即修改密码！', '提示', {
                confirmButtonText: '修改',
                type: 'warning'
              }).then(() => {
                this.changePwd()
              })
            }else {
              this.$confirm('您的密码为初始密码，有可能被冒名登录的可能性，是否进行修改？', '提示', {
                confirmButtonText: '修改',
                cancelButtonText: '跳过',
                type: 'warning'
              }).then(() => {
                this.changePwd()
              }).catch(() => {
                this.$router.push("/examDetails")
              });
            }
          }else {
            this.$router.push("/examDetails")
          }
        } else {
          handleAlert(resp.message, "info");
        }
      });
    },

    //忘记密码
    forgetPsssword() {
      handleAlert("请联系机构管理员协助找回密码。", "warning");
    },
    /*修改密码 弹框显示*/
    changePwd() {
      this.showChangePwd = true;
    },
    //提交
    submitForm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          handleConfirm("确认提交吗？", "warning")
              .then(() => {
                let params = Object.assign({}, this.dataForm);
                this.$ajax
                    .post(
                        localStorage.getItem("ApiUrl") + "/exam/changePssword",
                        params
                    )
                    .then((response) => {
                      let resp = response.data;
                      if (resp.success) {
                        handleMessage("修改密码成功。", "success");
                        this.showChangePwd = false;
                      } else {
                        handleAlert(resp.message, "error");
                      }
                    });
              })
        }
      });
    }
  }
}
</script>
