<template>
  <!-- 最外部div -->
  <div class="max">
    <!-- 头部 -->
    <header class="select-header" style="display: flex;justify-content: space-between">
      <div class="select-logo">
        <img src="../../public/static/img/logo.png" alt=""/>
      </div>
      <div style="display: flex;justify-content: space-around;align-items: center">
        <div class="flex-center">
          <img
              src="../../public/static/img/select/yh.png"
              alt=""
              class="select-user-img"
          />
          <span class="topText">  {{ xm }} </span>
        </div>
        <div style="margin:0 10px 0 10px;width: 2px;height:20px;border-left: 2px #959595 solid"></div>

        <div  v-if="deviceTestingFunction == 'true'" @click="showTestDevice = true" class="sbcs flex-center" style="cursor: pointer;margin-left:5px">
          <img src="../../public/static/img/select/sbcs.png" alt=""/>
          <a class="topText">设备测试</a>
          <div style="margin:0 10px 0 10px;width: 2px;height:20px;border-left: 2px #959595 solid"></div>
        </div>

        <div @click="changePwd" class="xgmm flex-center"  style="cursor: pointer;">
            <img src="../../public/static/img/select/czmm.png" alt=""/>
            <a  class="topText">修改密码</a>
          <div style="margin:0 10px 0 10px;width: 2px;height:20px;border-left: 2px #959595 solid"></div>
        </div>

        <div @click="logout" class="flex-center" style="cursor: pointer;">
          <img src="../../public/static/img/select/zxdl.png" alt=""/>
          <a class="topText">退出</a>
        </div>
      </div>
    </header>

    <div v-if="subjectShow" style="text-align: center; margin-top: 10%">
      <div
          v-for="ksxx in testMode"
          :key="ksxx.kmdm"
          class="select-cards-model"
          @click="getKsxx(ksxx.modeType == 1 ? 1 : 4, ksxx.modeType)"
      >
        <!-- 卡头显示科目 -->
        <div class="card-top">
          <!--          <span>{{ ksxx.kmmc }}</span>-->
        </div>
        <!-- 卡片内容区 -->
        <div class="card-bottom">
          <div class="card-bottom">
            <div class="card-bottom_text">
              {{ ksxx.modeName }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 导航 -->
    <nav class="select-nav" v-if="!subjectShow">
      <ul class="select-choose">
        <li
            class="choose_li"
            :class="{ active: zxksms == 1 }"
            @mouseover="showTheMenu('ks')"
            @click="fixedTheMenu('ks')"
            style="position: relative"
        >
          <!-- <a href="javascript:;" v-on:click="getksxx(1, 1)" class="c_1">考试</a> -->
          <a href="javascript:;" class="c_1">考试</a>
          <div
              class="selectOption"
              v-if="MenuName == 'ks'"
              @mouseleave="closeTheMenu('ks')"
          >
            <div class="xiala">
              <a href="javascript:;" v-on:click="getKsxx(1, 1)" class="xuanxiang1">进行中</a>
              <a href="javascript:;" v-on:click="getKsxx(2, 1)" class="xuanxiang1">未开始</a>
              <a href="javascript:;" v-on:click="getKsxx(3, 1)" class="xuanxiang1">已结束</a>
            </div>
          </div>
          <img
              v-if="MenuName == 'ks'"
              src="../../public/static/img/images/xiangxia2.png"
              class="xiangxia"
          />
          <img
              v-else
              src="../../public/static/img/images/xiangxia.png"
              class="xiangxia"
          />
        </li>


        <li
            class="choose_li"
            :class="{ active: zxksms == 2 }"
            @mouseover="showTheMenu('lx')"
            @click="fixedTheMenu('lx')"
            style="position: relative"
        >
          <!-- <a href="javascript:;" v-on:click="getksxx(3, 1)" class="c_1">练习</a> -->
          <a href="javascript:;" class="c_1">练习</a>
          <div
              class="selectOption"
              v-if="MenuName == 'lx'"
              @mouseleave="closeTheMenu('ks')"
          >
            <div class="xiala">
              <a href="javascript:;" v-on:click="getKsxx(1, 2)" class="xuanxiang1">进行中</a>
              <a href="javascript:;" v-on:click="getKsxx(2, 2)" class="xuanxiang1">未开始</a>
              <a href="javascript:;" v-on:click="getKsxx(3, 2)" class="xuanxiang1">已结束</a>
            </div>
          </div>
          <img
              v-if="MenuName == 'lx'"
              src="../../public/static/img/images/xiangxia2.png"
              class="xiangxia"
          />
          <img
              v-else
              src="../../public/static/img/images/xiangxia.png"
              class="xiangxia"
          />
        </li>
        <li
            class="choose_li"
            :class="{ active: zxksms == 3 }"
            @mouseover="showTheMenu('wj')"
            @click="fixedTheMenu('wj')"
            style="position: relative"
        >
          <!-- <a href="javascript:;" v-on:click="getksxx(2, 1)" class="c_1">问卷</a> -->
          <a href="javascript:;" class="c_1">问卷</a>
          <div
              class="selectOption"
              v-if="MenuName == 'wj'"
              @mouseleave="closeTheMenu('ks')"
          >
            <div class="xiala">
              <a href="javascript:;" v-on:click="getKsxx(1, 3)" class="xuanxiang1">进行中</a>
              <a href="javascript:;" v-on:click="getKsxx(2, 3)" class="xuanxiang1">未开始</a>
              <a href="javascript:;" v-on:click="getKsxx(3, 3)" class="xuanxiang1">已结束</a>
            </div>
          </div>
          <img
              v-if="MenuName == 'wj'"
              src="../../public/static/img/images/xiangxia2.png"
              class="xiangxia"
          />
          <img
              v-else
              src="../../public/static/img/images/xiangxia.png"
              class="xiangxia"
          />
        </li>

      </ul>
    </nav>

    <body v-loading="loading" v-if="!subjectShow">
    <!--分类小窗  -->

    <!-- <div class="menu_1">
      <ul class="menu_ul"> -->
    <!-- 第一个小窗 -->
    <!-- <li
          class="menu_ul_li"
          v-if="MenuName == 'ks'"
          @mouseleave="closeTheMenu('ks')"
        >
          <div class="menu_ul_li_div"></div>
          <span class="menu_ul_li_span1">
            <img
              src="../../public/static/img/images/navbg505.png"
              width="100%"
            />
            <div class="xiala">
              <a href="" class="xuanxiang1">全部考试</a>
              <a href="" class="xuanxiang1">已开始</a>
              <a href="" class="xuanxiang1">未开始</a>
              <a href="" class="xuanxiang1">已结束</a>
            </div>
          </span>
        </li> -->

    <!-- 第二个小窗 -->
    <!-- <div id="demo2"></div> -->
    <!-- <li
          class="menu_ul_li"
          v-if="MenuName == 'wj'"
          @mouseleave="closeTheMenu('ks')"
        >
          <div class="menu_ul_li_div"></div>
          <span class="menu_ul_li_span2">
            <img
              src="../../public/static/img/images/navbg505.png"
              width="100%"
            />
            <div class="xiala">
              <a href="" class="xuanxiang1">全部考试</a>
              <a href="" class="xuanxiang1">已开始</a>
              <a href="" class="xuanxiang1">未开始</a>
              <a href="" class="xuanxiang1">已结束</a>
            </div>
          </span>
        </li> -->
    <!-- 第三个小窗 -->
    <!-- <div id="demo3"></div> -->
    <!-- <li
          class="menu_ul_li"
          v-if="MenuName == 'lx'"
          @mouseleave="closeTheMenu('ks')"
        >
          <div class="menu_ul_li_div"></div>
          <span class="menu_ul_li_span3">
            <img
              src="../../public/static/img/images/navbg505.png"
              width="100%"
            />
            <div class="xiala">
              <a href="" class="xuanxiang1">全部考试</a>
              <a href="" class="xuanxiang1">已开始</a>
              <a href="" class="xuanxiang1">未开始</a>
              <a href="" class="xuanxiang1">已结束</a>
            </div>
          </span>
        </li> -->
    <!-- </ul>
    </div> -->

    <!-- 所显示的科目 -->
    <main class="select-main" v-if="ksxxlist.length > 0">
      <div
          v-for="ksxx in ksxxlist"
          class="select-cards"
          :key="ksxx.kmdm"
      >
        <!-- 卡头显示科目 -->
        <div class="card-top">
          <span>{{ ksxx.kmmc }}</span>
        </div>
        <!-- 卡片内容区 -->
        <div class="card-bottom">
          <div class="card-bottom-left">
            <img
                src="../../public/static/img/select/book.png"
                alt=""
                class="card_img"
            />
          </div>
          <div class="card-bottom-right">
            <div class="card-bottom_text">
              <p class="card_p1">开始时间：{{ ksxx.kssj }}</p>
              <p class="card_p2">结束时间：{{ ksxx.jssj }}</p>
              <p class="card_p3" v-if="isActive == '1'">
                考试状态：<span>{{ checkKszt(ksxx.lbzt) }}</span>
              </p>
              <p class="card_p3" v-if="isActive == '2'">
                考试状态：<span>未开始</span>
              </p>
              <p class="card_p3" v-if="isActive == '3'">
                考试状态：<span>已结束</span>
              </p>
              <!--              <p class="card_p3" v-if="isActive=='4'">考试状态：<span></span>  </p>-->
            </div>
            <div
                class="card_btn"
                v-if="ksxx.lbzt == '1' && ksxx.zxksms == '1'"
            >
              <button v-on:click="enterExam(ksxx)" class="btn1">
                继续考试
              </button>
            </div>
            <div
                class="card_btn"
                v-if="isActive == '1' && ksxx.lbzt == '2' && ksxx.zxksms == '1'"
            >
              <button v-on:click="enterExam(ksxx)" class="btn2">
                进入考试
              </button>
            </div>
            <div
                class="card_btn"
                v-if="isActive == '3' && ksxx.zxksms == '1'"
            >
              <button v-on:click="enterExam(ksxx)" class="dis_btn">
                考试结束
              </button>
            </div>
            <div
                class="card_btn"
                v-if="isActive =='1' && ksxx.lbzt == '4' && ksxx.zxksms == '1'"
            >
              <button v-on:click="enterExam(ksxx)" class="dis_btn">
                已交卷
              </button>
            </div>

            <div
                class="card_btn"
                v-if="ksxx.lbzt == '1' && ksxx.zxksms == '2'"
            >
              <button v-on:click="enterPractice(ksxx)" class="btn2">
                进入练习
              </button>
            </div>
            <div
                class="card_btn"
                v-if="ksxx.lbzt == '2' && ksxx.zxksms == '2'"
            >
              <button v-on:click="enterPractice(ksxx)" class="btn2">
                练习未开始
              </button>
            </div>
            <div
                class="card_btn"
                v-if="ksxx.lbzt == '3' && ksxx.zxksms == '2'"
            >
              <button v-on:click="enterPractice(ksxx)" class="dis_btn">
                练习结束
              </button>
            </div>
            <div
                class="card_btn"
                v-if="ksxx.lbzt == '1' && ksxx.zxksms == '3'"
            >
              <button v-on:click="enterQuestionnaire(ksxx)" class="btn2">
                进入问卷
              </button>
            </div>
            <div
                class="card_btn"
                v-if="isActive =='1' && ksxx.lbzt == '4' && ksxx.zxksms == '3'"
            >
              <button v-on:click="enterQuestionnaire(ksxx)" class="dis_btn">
                已交卷
              </button>
            </div>

          </div>
        </div>
      </div>
    </main>

    <main class="noo-main" v-if="noExam && zxksms != '3 '">
      <div class="noo-box">
        <img
            src="../../public/static/img/select/zwks.png"
            alt=""
            class="noo-box-img"
        />
        <p class="noo-box-p">暂无考试 ...</p>
      </div>
    </main>

    <main class="noo-main" v-if="noExam && zxksms == '3'">
      <div class="noo-box">
        <img
            src="../../public/static/img/select/zwks.png"
            alt=""
            class="noo-box-img"
        />
        <p class="noo-box-p">暂无问卷 ...</p>
      </div>
    </main>
    </body>
    <el-dialog
        title="修改密码"
        :visible.sync="showChangePwd"
        :width="dialogWidth"
        top="10vh"
        :show-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <el-form
          :model="dataForm"
          label-width="80px"
          :rules="dataFormRules"
          ref="dataForm"
          :size="size"
          label-position="right"
      >
        <el-form-item label="原密码" prop="lastpassword">
          <el-input
              v-model="dataForm.lastpassword"
              placeholder="请输入原密码"
              type="password"
              auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newpassword">
          <el-input
              v-model="dataForm.newpassword"
              placeholder="请设置新密码"
              type="password"
              auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="qrpassword">
          <el-input
              v-model="dataForm.qrpassword"
              placeholder="请确认新密码"
              type="password"
              auto-complete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :size="size" v-on:click="showChangePwd = false"
        >取消
        </el-button
        >
        <el-button
            :size="size"
            type="primary"
            @click="submitForm"
            :loading="editLoading"
        >提交
        </el-button
        >
      </div>
    </el-dialog>

    <!-- 设备测试 -->
    <el-dialog
        :visible.sync="showTestDevice"
        v-if="showTestDevice"
        style="text-align:center!important;width: 100%;height: 100%"
        :before-close="testDeviceHandleClose"
        :show-close="false"
        :center=true>
      <iframe :src="'/static/TRTC/testDevice.html?apiUrl='+getUrl()"
              style="width:100%;height:500px;background:white"></iframe>
    </el-dialog>

  </div>
</template>
<script>
import {handleAlert, handleConfirm, handleMessage} from "@/utils/confirm";
import {getTokenInfo} from "@/utils/auth";

export default {
  name: "examDetails",
  data: function () {
    //此处即表单发送验证之前，验证新密码与原密码
    let validateNewPassword = (rule, value, callback) => {
      if (value === this.dataForm.lastpassword) {
        callback(new Error("新密码不能与原密码相同!"));
      } else if (value.toString().length < 6 || value.toString().length > 18) {
        callback(new Error("密码长度为6-18位"));
      } else {
        callback();
      }
    };

    //此处即表单发送之前验证  验证新密码与再次确认
    let validateNewPassword2 = (rule, value, callback) => {
      if (value !== this.dataForm.newpassword) {
        callback(new Error("与新密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      subjectShow: true,
      loading: false,
      lbzt: "1",
      ksxxlist: [],
      checkexam: {kmdm: "", ksdm: ""},
      isActive: "",
      zxksms: "1",
      exam_answer: {kmdm: "", ksdm: "", kmmc: ""},
      xm: "",
      noExam: false,
      showChangePwd: false, //是否显示修改密码弹框
      dialogWidth: "", //dialog的宽度，缩放浏览器改变大小
      editLoading: false, //载入图标
      size: "small",
      dataForm: {
        lastpassword: "",
        newpassword: "",
        qrpassword: "",
      },
      //设置属性
      dataFormRules: {
        lastpassword: [
          {required: true, message: "请输入原密码", trigger: "blur"},
        ],
        newpassword: [
          {required: true, message: "请设置新密码", trigger: "blur"},
          {validator: validateNewPassword, trigger: "blur"},
        ],
        qrpassword: [
          {required: true, message: "请确认新密码", trigger: "blur"},
          {validator: validateNewPassword2, trigger: "blur"},
        ],
      },
      testMode: [],
      testModeTypeList: {
        1: "在线考试",
        2: "练习模式",
        3: "调查问卷",
      },
      MenuName: "",
      showTestDevice: false,       //设备测试开关
      deviceTestingFunction:true
    };
  },
  created() {
    let _this = this;
    _this.setDialogWidth();
    window.addEventListener('message', this.testHandleMessage)
  },
  mounted() {
    if(localStorage.getItem("deviceTestingFunction")){
      this.deviceTestingFunction = localStorage.getItem("deviceTestingFunction")
    }
    let _this = this;
    _this.getKsxx(_this.lbzt, 1);
    _this.xm = getTokenInfo().xm;

    window.onresize = () => {
      return (() => {
        _this.setDialogWidth();
      })();
    };
  },
  destroyed() {
    window.removeEventListener('message', this.testHandleMessage, false)
  },
  methods: {
    // 悬停展示,离开关闭.
    showTheMenu(name) {
      this.MenuName = name;
    },
    fixedTheMenu(name) {
      this.MenuName = name;
    },
    closeTheMenu(name) {
      this.MenuName = "";
    },
    //设置Dialog大小
    setDialogWidth() {
      let val = document.body.clientWidth;
      const def = 800; // 默认宽度
      if (val < def) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = "30%";
      }
    },
    //获取url
    getUrl() {
      return localStorage.getItem('ApiUrl');
    },
    //获取考试信息
    getKsxx(element, ZXKSMS) {
      let parameter = {
        lbzt: "",
        zxksms: ""
      };
      parameter.lbzt = element;
      parameter.zxksms = ZXKSMS;
      this.subjectShow = false;
      let _this = this;
      _this.loading = true;
      _this.$ajax
          .post(localStorage.getItem("ApiUrl") + "/exam/getTestList", parameter)
          .then((response) => {
            let resp = response.data;
            if (resp.success) {
              _this.ksxxlist = resp.content;
              _this.isActive = element;
              _this.zxksms = ZXKSMS;
              _this.loading = false;
              //这么做的好处就是点击进入考试的时候，退出来，未开始的那个图片不闪烁。
              Tool.isEmpty(_this.ksxxlist)
                  ? (_this.noExam = true)
                  : (_this.noExam = false);
            } else {
              handleAlert(resp.message, "error");
            }
          });
    },
    //点击进入考试
    enterExam(obj) {
      let _this = this;
      _this.checkexam.kmdm = obj.kmdm;
      _this.checkexam.ksdm = obj.ksdm;
      _this.$ajax
          .post(localStorage.getItem("ApiUrl") + "/exam/checkEnterExam", _this.checkexam)
          .then((response) => {
            let resp = response.data;
            if (resp.success) {
              _this.exam_answer.kmdm = _this.checkexam.kmdm;
              _this.exam_answer.ksdm = _this.checkexam.ksdm;
              _this.exam_answer.kmmc = resp.content[0].kmmc;
              SessionStorage.set(SESSION_KEY_EXAM_ANSWER, _this.exam_answer);
              _this.$router.push("/answer");
            } else {
              handleAlert(resp.message, "error");
            }
          });
    },
    //点击进入调查问卷
    enterQuestionnaire(obj) {
      let _this = this;
      _this.checkexam.kmdm = obj.kmdm;
      _this.checkexam.ksdm = obj.ksdm;
      _this.$ajax
          .post(
              localStorage.getItem("ApiUrl") + "/exam/checkEnterQuestionnaire",
              _this.checkexam
          )
          .then((response) => {
            let resp = response.data;
            if (resp.success) {
              _this.exam_answer.kmdm = _this.checkexam.kmdm;
              _this.exam_answer.ksdm = _this.checkexam.ksdm;
              _this.exam_answer.kmmc = resp.content[0].kmmc;
              SessionStorage.set(SESSION_KEY_EXAM_ANSWER, _this.exam_answer);
              _this.$router.push("/questionnaire");
            } else {
              handleAlert(resp.message, "error");
            }
          });
    },
    //点击进入练习
    enterPractice(obj) {
      let _this = this;
      _this.checkexam.kmdm = obj.kmdm;
      _this.checkexam.ksdm = obj.ksdm;
      _this.$ajax
          .post(
              localStorage.getItem("ApiUrl") + "/exam/checkEnterPractice",
              _this.checkexam
          )
          .then((response) => {
            let resp = response.data;
            if (resp.success) {
              _this.exam_answer.kmdm = _this.checkexam.kmdm;
              _this.exam_answer.ksdm = _this.checkexam.ksdm;
              _this.exam_answer.kmmc = resp.content[0].kmmc;
              SessionStorage.set(SESSION_KEY_EXAM_ANSWER, _this.exam_answer);
              _this.$router.push("/practice");
            } else {
              handleAlert(resp.message, "error");
            }
          });
    },
    checkKszt(lbzt) {
      if (lbzt == "1" && this.zxksms == "1") {
        return "考试中";
      }
      if (lbzt == "1" && this.zxksms == "2") {
        return "练习中";
      }
      if (lbzt == "1" && this.zxksms == "3") {
        return "调查中";
      }

      if (lbzt == "2") {
        return "未考试";
      }
      if (lbzt == "3") {
        return "已结束";
      }
      if (lbzt == "4") {
        return "已交卷";
      }

      if (lbzt == "4" && this.zxksms == "3") {
        return "已提交";
      }
    },
    /*修改密码 弹框显示*/
    changePwd() {
      this.showChangePwd = true;
    },
    //提交
    submitForm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          handleConfirm("确认提交吗？", "warning")
              .then(() => {
                let params = Object.assign({}, this.dataForm);
                this.$ajax
                    .post(
                        localStorage.getItem("ApiUrl") + "/exam/changePssword",
                        params
                    )
                    .then((response) => {
                      let resp = response.data;
                      if (resp.success) {
                        handleMessage("修改密码成功。", "success");
                        this.showChangePwd = false;
                      } else {
                        handleAlert(resp.message, "error");
                      }
                    });
              })
        }
      });
    },
    //退出登陆
    logout() {
      let _this = this;
      handleConfirm("确认退出系统吗？", "warning")
          .then(() => {
            _this.$ajax
                .get(localStorage.getItem("ApiUrl") + "/exam/logout")
                .then((response) => {
                  let resp = response.data;
                  if (resp.success) {
                    handleMessage("您已安全退出系统,请重新登录。", "success");
                    //清空token
                    _this.removeToken();
                    _this.$router.push("/login");
                  } else {
                    handleAlert(resp.message, "error");
                  }
                });
          })
          .catch(() => {
          });
    },
    //清空token
    removeToken() {
      Tool.removeAccessToken();
      Tool.removeRefreshToken();
    },
    //获取版本号
    getVer() {
      //获取随机数作为版本号
      return Math.random() * 100000000000000000
    },
    //设备测试关闭前
    testDeviceHandleClose() {
      this.$confirm('确定要退出设备检测吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.showTestDevice = false
        return true
      }).catch((err) => {
        return false
      });
    },
    //接受到子组件的回掉事件
    testHandleMessage(event) {
      let eventData = event.data
      if (eventData.methodName == "testingFinish") {
        let flag = false
        eventData.testingResult.forEach((value, key) => {
          if (!value) {
            flag = true
            console.log(key + "检测异常")
          }
        });
        this.showTestDevice = false
      }
    }
  },
};
</script>
<style scoped="scoped">
font {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.choose_li {
  padding: 0px 5px 0px 5px;
  margin-left: 75px;
  margin-right: 75px;
}

.select-nav {
  border-bottom: 0px;
}

.select-choose {
  margin: auto auto;
  width: 700px;
}

.select-choose > .active {
  border-bottom: 3px solid rgb(51, 204, 153);
}

.selectOption {
  width: 100px;
  min-height: 50px;
  padding: 10px;
  text-align: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #eee;
  position: absolute;
  left: -5px;
  top: 80px;
  float: left;
  z-index: 100;
}

.selectOption::after {
  content: "";
  width: 10px;
  height: 10px;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  background-color: #fff;
  -webkit-transform: rotateZ(315deg);
  transform: rotateZ(315deg);
  position: absolute;
  left: 54px;
  /*left=90时候 是不算border的 90+4是正确的*/
  top: -5px;
  /*left=2才是要求的距离顶部为0*/
}

.xuanxiang1 {
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #454545;
  text-decoration: none;
  /* float: left; */
  /* list-style:none;  */
  /* width:auto;  */

}

.xuanxiang1:hover {
  color: #33cc99;
}

.xiangxia {
  width: 20px;
  margin-left: 5px;
}

/* 新增手机端 */
@media screen and (max-width: 700px) {
  .choose_li {
    padding: 0px 5px 0px 5px;
    margin-left: 35px;
    margin-right: 35px;
  }

  .select-choose {
    margin: auto auto;
    width: 500px;
  }
}

@media screen and (max-width: 600px) {
  .select-choose {
    margin: auto auto;
    width: 500px;
  }

  c_1 {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .select-choose {
    margin: auto auto;
    width: 300px;
  }

  .choose_li {
    padding: 0px 5px 0px 5px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .xiangxia {
    width: 10px;
    margin-left: 2px;
  }

  .selectOption {
    width: 70px;
    /* height: 160px; */
    position: absolute;
    left: -12px;
    top: 36px;
  }

  .xuanxiang1 {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }

  .selectOption::after {
    width: 5px;
    height: 5px;
    position: absolute;
    left: 32px;
    /*left=90时候 是不算border的 90+4是正确的*/
    top: -4px;
    /*left=2才是要求的距离顶部为0*/
  }
}

@media screen and (max-width: 759px) {
  .sbcs {
    display: none;
  }
}
</style>
