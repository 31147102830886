<template>
  <div>
    <vplayer class="player" v-if="mediaType==='video' && controlDisplay"
             @onpause="pause"
             :playerOptions="vOption"
    />
    <aplayer class="player" v-if="mediaType==='radio' && controlDisplay"
             @onpause="pause"
             :playerOptions="aOption"/>
    <img v-if="mediaType==='image' && controlDisplay"
         :src="imgSrc">
  </div>
</template>
<script>
import {aplayer, vplayer} from 'vue-hls-player'

export default {
  name: 'HlsPlayer',
  components: {vplayer, aplayer},
  data() {
    return {
      controlDisplay: false,
      aOption: {
        src: '',//地址
        preload: true,//是否预下载，默认为true
        autoplay: false,//是否自动播放（兼容性不太好），默认为false
        isLoop: false,//是否循环，默认不循环
        controls: 'progress,current,durration',
      },
      vOption: {
        type: '',//媒体类型，m3u8请给application/x-mpegURL或者application/vnd.apple.mpegURL，其他的会默认为普通音视频，注：微信不支持m3u8
        src: '',//视频地址
        preload: true,//是否预下载，默认为true
        autoplay: false,//是否自动播放（兼容性不太好），默认为false
        isLoop: false,//是否循环，默认不循环
        playsinline: false, //h5是否行内播放，默认false，有兼容性问题
        poster: '', //封面，仅视频有
        controls: 'progress,current,durration,volume', //显示的控件,volume只有视频有，没有配置controls项则全部显示。
        crossOrigin: false //设置视频的 CORS 设置。
      },
      imgSrc: ""
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      new Promise((resolve) => {
        switch (this.mediaType) {
          case 'video':
            this.vOption.src = this.streamMediaSrc
            break;
          case 'radio':
            this.aOption.src = this.streamMediaSrc
            break;
          case 'image':
            this.imgSrc = this.streamMediaSrc
            break;
        }
        resolve()
      }).then(() => {
        this.controlDisplay = true
      })
    },
    pause(e) {

    }
  },
  props: {
    streamMediaSrc: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    mediaType: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped="scoped">
@media screen and (min-width: 1200px) {

  img {
    max-width: 600px;
    max-height: 600px;
  }

  .player {
    max-width: 600px;
  }

}

/* 大型设备（大台式电脑，1200px 起） */

@media screen and (max-width: 992px) {

  img {
    max-width: 496px;
    max-height: 496px;
  }

  .player {
    max-width: 496px;
  }

}

/* 中型设备（台式电脑，992px 起） */

@media screen and (max-width: 768px) {
  img {
    max-width: 384px;
    max-height: 384px;
  }

  .player {
    max-width: 384px;
  }

}

/* 小型设备（平板电脑，768px 起） */

@media screen and (max-width: 480px) {
  img {
    max-width: 240px;
    max-height: 240px;
  }

  .player {
    max-width: 240px;
  }
}

/* 超小设备（手机，小于 768px） */


</style>

