import {MessageBox, Message} from "element-ui";

//带确定框的。
export function handleAlert(text, type) {
    return MessageBox.alert(text, '信息', {
        message: text,
        type: type,
        center: true,
        confirmButtonText: '确定',
    })
}

//不带确定框的
export function handleMessage(text, type) {
    return Message({
        message: text,
        type: type,
        center: true,
    })
}

//提示框
export function handleConfirm(text, type) {
    return MessageBox.confirm(text, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
        closeOnPressEscape: false, //是否可通过按下 ESC 键关闭 MessageBox
        type: type,
        center: true,

    })
}

//校验输入框内容是否为空。
export function Validator(value, text) {
    if (Tool.isEmpty(value)) {
        handleAlert(text + '不能为空', 'warning').then(res => {
            return false;
        })
    } else {
        return true;
    }
}