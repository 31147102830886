<template>
  <!--  练习模式 -->
  <div>
    <!-- 全局加载界面-->
    <div v-loading.fullscreen.lock="fullscreenLoading"></div>

    <el-dialog
        :visible.sync="showAttention"
        :show-close="false"
        class="dialogClass"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :width="deviceWidth"
    >
      <!-- 弹窗主体 -->
      <div class="dialog_box" v-loading.fullscreen.lock="divLoading">
        <!-- 弹窗头部 ----------------------------------------------------------------------------->
        <div class="dialog_top">
          <span class="dialog_top_text">在线练习</span>
          <div class="int">
            <img src="../../public/static/img/practice/cuowu.png" alt="" class="int_img" @click="backToSelect">
          </div>
        </div>
        <!-- 弹窗白色内容区 ----------------------------------------------------------------------->
        <div class="dialog_bottom">
          <!-- 练习模式区域 -->
          <div class="bottom_type margin-lr-df flex">
            <div>
              <p class="green"></p>
            </div>
            <div>
              <div class="padding-left">
                <span class="headline">练习模式</span>
              </div>
              <div class="btn_box">
                <div class="btn_type1">
                  <img src="../../public/static/img/practice/shunxu.png" alt="" class="btn_type1_img">
                  <span class="btn_type1_text" @click="startTest(0)">顺序练习</span>
                </div>
                <div class="btn_type1">
                  <img src="../../public/static/img/practice/monikaoshi.png" alt="" class="btn_type1_img">
                  <span class="btn_type1_text" @click="startTest(1)">模拟考试</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 题型练习区域 -->
          <div class="bottom_type margin-lr-df flex">
            <div>
              <p class="green"></p>
            </div>
            <div>
              <div class="padding-left">
                <span class="headline">题型练习</span>
                <span class="headline_2">  共计（{{ stlxTotal }}题）</span>
              </div>
              <div class="btn_box">
                <div v-for="stlxItem in stlxxx">
                  <div class="btn_type2" @click="startTestByStlx(stlxItem.stlx,stlxItem.tzmc)" v-if="getStlx(stlxItem.stlx)!==''">
                    <img src="../../public/static/img/practice/zuheti.png"
                         alt=""
                         class="btn_type2_img"
                         v-if="getStlx(stlxItem.stlx).stlx=='0'">
                    <img src="../../public/static/img/practice/danxuanti.png"
                         alt=""
                         class="btn_type2_img"
                         v-if="getStlx(stlxItem.stlx).stlx=='1'">
                    <img src="../../public/static/img/practice/duoxuanti.png"
                         alt=""
                         class="btn_type2_img"
                         v-if="getStlx(stlxItem.stlx).stlx=='2'">
                    <img src="../../public/static/img/practice/panduanti.png"
                         alt=""
                         class="btn_type2_img"
                         v-if="getStlx(stlxItem.stlx).stlx=='3'">
                    <img src="../../public/static/img/practice/tiankongti.png"
                         alt=""
                         class="btn_type2_img"
                         v-if="getStlx(stlxItem.stlx).stlx=='4'">
                    <img src="../../public/static/img/practice/jiandati.png"
                         alt=""
                         class="btn_type2_img"
                         v-if="getStlx(stlxItem.stlx).stlx=='5'">
                    <span class="btn_type1_text">{{ stlxItem.tzmc }}</span>
                    <span class="btn_type2_text">({{ stlxItem.total }}道)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 弹窗按钮摆放区 -->
          <div class="bottom_box margin-lr-df margin-top">
            <button class="start_btn" @click="backToSelect">返回列表</button>
          </div>
        </div>
      </div>

    </el-dialog>

  </div>
</template>

<script>
import {handleAlert} from "@/utils/confirm";
import {getTokenInfo} from "@/utils/auth";


export default {
  name: "practice",
  components: {},
  data: function () {
    return {
      deviceWidth: '300px',       //弹框宽度，根据设备确定
      width: '',                  //浏览器窗口宽度
      tokenInfo: null,           //token信息
      session: null,               //会话信息
      showAttention: false,      //是否显示注意事项
      fullscreenLoading: true,   //全局loading
      divLoading: false,           //弹窗loading
      sjxq: [],                  //试卷详情
      jksz: {},                  //机考设置
      ksxx: {},                  //考试科目
      xsjbxx: {},                //学生信息
      xszdxx: [],                //作答信息
      dqst: 0,                   //当前试题
      dqstid: 0,                 //当前试题ID
      stxx: {},                  //试题信息
      stlxxx: [],                //试题类型信息
      stlxMap: new Map(),        //试题类型Map
    }
  },
  mounted: function () {
    //获取所有试题类型
    this.getAllStlx()
    window.addEventListener('resize', this.getWidth);
    this.getWidth()
    this.session = SessionStorage.get(SESSION_KEY_EXAM_ANSWER)
    this.tokenInfo = getTokenInfo()
    this.showAttention = true
  },
  destroyed() {
    window.removeEventListener('resize', this.getWidth)
  },
  methods: {
    getWidth() {
      this.width = window.innerWidth - 70 + 'px';
      if ((window.innerWidth - 70) < 812) {
        this.deviceWidth = '300px'
      } else {
        this.deviceWidth = '700px'
      }
    },

    //返回选择菜单
    backToSelect: function () {
      this.$router.push("/examDetails")
    },
    getTestQuestionsTotal() {
      // 获取试题题型统计以及总数
      this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/getTestQuestionsTotal', this.session).then((response) => {
        let resp = response.data
        if (resp.success) {
          this.stlxxx = resp.content
        } else {
          handleAlert(resp.message, 'error')
          this.backToSelect()
        }
      });

    },
    //获取所有试题类型
    getAllStlx() {
      this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/getAllStlx').then((response) => {
        let resp = response.data
        if (resp.success) {
          resp.content.forEach(item => {
            this.stlxMap.set(item.id + '', item)
          })
          //获取stxx表中题型
          this.getTestQuestionsTotal()
        } else {
          handleAlert(resp.message, 'error')
          this.backToSelect()
        }
      });
    },
    //开始练习
    startTest(type) {
      this.divLoading = true
      //开始考试
      this.$ajax.post(localStorage.getItem('ApiUrl') + '/practice/startPractice', this.session).then((response) => {
        let resp = response.data
        if (resp.success) {
          this.getTestPaper(type)
        } else {
          this.divLoading = false
          handleAlert(resp.message, 'error')
          this.backToSelect()
        }
      });
    },
    //开始练习，根据试题类型
    startTestByStlx(stlx,tzmc) {
      //开始考试
      this.$ajax.post(localStorage.getItem('ApiUrl') + '/practice/startPractice', this.session).then((response) => {
        let resp = response.data
        if (resp.success) {
          this.getTestPaper(0, stlx,tzmc)
        } else {
          handleAlert(resp.message, 'error')
          this.backToSelect()
        }
      });
    },
    //获取试题信息
    getTestPaper(type, stlx,tzmc) {
      //type 练习模式
      //stlx 试题类型
      //tzmc 题组名称
      let sjms = 2 //试卷模式  1试卷 2闯关 练习模式默认2
      let stly = 1 //试题来源  1题库组卷 2图片上传 练习模式默认1
      let dtfs = 1 //答题方式  1在线答题 2拍照上传 练习模式默认1

      /*
       * 不管什么模式，一律从stxx表中取题，不再涉及zgtxxb和kgtxxb
       * 题库组卷 + 在线答题  正常（试卷、闯关均可），
       * 题库组卷 + 拍照上传  正常（试卷、闯关均可）
       * 图片上传 + 在线答题  图片上传一定是闯关模式，并且答题区在最下方
       * 图片上传 + 拍照上传  图片上传一定是闯关模式，并且答题区在最下方
       */
      let _this = this

      if (stlx !== undefined) {
        _this.session.stlx = stlx
        _this.session.tzmc = tzmc
      } else {
        _this.session.stlx = null
        _this.session.tzmc = null
      }
      SessionStorage.set(SESSION_KEY_EXAM_ANSWER, _this.session)
      if (stly == '1') {
        if (type === 0) {
          this.$router.push({name: 'sequentialPractice'})
        } else {
          this.$router.push({name: 'mockExam'})
        }
        this.fullscreenLoading = false;
        this.divLoading = false
      }
    },
    //获取试题类型名称
    getStlx: function (stlxid, default_name = '') {
      return this.stlxMap.get(stlxid)
    },
    handleFalse() {
      //终止
      return false;
    }
  },
  computed: {
    stlxTotal() {
      let total = 0;
      this.stlxxx.forEach(item => {
        total += item.total
      })
      return total
    }
  }

}
</script>
<style>
.dialogClass .el-dialog__body {
  padding: 0px 0px !important;
}

.dialogClass .el-dialog__header {
  padding: 0px 0px 0px !important;
}
</style>
<style scoped>
.dialog_box {
  width: 100%;
  height: 100%;
  box-shadow: 0px 9px 15.48px 2.52px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
}

.dialog_top {
  width: 100%;
  height: 50px;
  background-color: #33cc99;
  line-height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
}

.dialog_top_text {
  font-size: 18px;
  color: #fff;
}

.int {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgba(40, 154, 137, 0.2);
  float: right;
  margin-top: 7.5px;
  text-align: center;
  line-height: 38.5px;
}

.int:hover {
  background-color: rgba(40, 154, 137, 0.4);
}

.int_img {
  width: 15px;
  height: auto;
}

.dialog_bottom {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.bottom_type {
  margin-top: 20px;
}

.green {
  width: 5px;
  height: 25px;
  background-color: #33cc99;
  display: inline-block;
  margin-top: 7px;
}

.headline {
  font-size: 26px;
  color: #282928;
  margin-left: 5px;
}

.btn_box {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

.btn_type1 {
  width: 140px;
  height: 60px;
}

.btn_type1,
.btn_type2 {
  border-width: 1.5px;
  border-color: #ededed;
  border-style: solid;
  line-height: 60px;
  margin: 4px 9px;
  display: flex;

  justify-content: start;
  align-items: center;
  cursor: pointer;
}

.btn_type2 {
  width: 197px;
  height: 60px;
}

.btn_type1:hover,
.btn_type2:hover {
  border-color: #33cc99;
}

.btn_type1_text {
  font-size: 20px;
  margin-left: 5px;
}

.btn_type1_img {
  width: 28px;
  height: auto;
  margin-left: 10px;
}

.btn_type2_img {
  width: 28px;
  height: auto;
  margin-left: 10px;
}

.btn_type2_text {
  font-size: 14px;
  color: #00000087;
  height: 55px;
  padding-left: 4px;
}

.bottom_box {
  width: auto;
  height: 75px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e2e7e7;
}

.start_btn,
.cancel_btn {
  width: 100px;
  height: 36px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.start_btn:hover,
.cancel_btn:hover {
  font-weight: 600;
}

.start_btn {
  background-color: rgba(51, 204, 153, 0.94);
  color: #fff;
}

.cancel_btn {
  background-color: #ededed;
  margin-left: 10px;
  color: #383938;
}

/* 洪璐定义的一些样式 */

.margin-lr-df {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-top {
  margin-top: 20px;
}

.flex {
  display: flex;
}

.padding-left {
  padding-left: 4px;
}


@media screen and (max-width: 480px){
  .headline {
    font-size: 18px;
    color: #282928;
    margin-left: 5px;
  }
  .green {
    width: 5px;
    height: 25px;
    background-color: #33cc99;
    display: inline-block;
    margin-top: 0px;
  }

  .btn_type1_text {
    font-size: 14px;
    margin-left: 5px;
  }

  .btn_type2_text {
    font-size: 14px;
    color: #00000087;
    height: 55px;
    padding-left: 4px;
  }

  .btn_type1_img {
    width: 20px;
    height: auto;
    margin-left: 10px;
  }

  .btn_type2_img {
    width: 20px;
    height: auto;
    margin-left: 10px;
  }
}
</style>
